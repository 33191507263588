import React,{useEffect} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import './aboutus.css';

import imgabout from '../Images/about.jpg';
import {  Link } from "react-router-dom";


import { Speedometer2,Bootstrap,Grid,People,List,Table,House,Facebook,Instagram,Bell,InfoCircle,BoxArrowLeft,Youtube} from 'react-bootstrap-icons';
import { PlayBtn,BarChart,Clipboard2Data,Shuffle,PersonCheck,Lightning,Activity,PersonFillAdd,BoxArrowRight,Wallet2,PersonCircle} from 'react-bootstrap-icons';

import ReactGA from 'react-ga4';

const Aboutus = (props) => {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <Container fluid>
      <Row id="Main Row">
        <Row className="mt-3 mb-3">
          <Col style={{ width: "260px" }} xs={3}>
            <img
              src={imgabout}
              style={{ height: "250px", borderRadius: "8px" }}
            />
          </Col>
          <Col id="heading" className="">
            <Card className="purposeCard">
              <Card.Header className="purposeHeader mb-1">
                About Team Insights Pro
              </Card.Header>
              <div className="cardContent">
                Team Insights Pro's founders share a common background with you
                – individuals immersed in the world of coaching youth sports at
                varying levels. Whether engaged in competitive teams or local
                recreational leagues, they recognize a consistent challenge. How
                can one maintain competitiveness while adhering to league
                requirements regarding playing time and other restrictions? In
                response, they harnessed the capabilities of their company
                Niche-Analytics, drawing upon their rich history of employing
                advanced analytics, AI, and optimization. The result is a
                user-friendly sports analytics platform designed for all. Even
                better, they offer a streamlined version for free. Because,
                while the essence is rooted in enjoyment, it undeniably becomes
                more gratifying when accompanied by victories. Elevate your
                coaching experience with Team Insights Pro – where success meets
                simplicity.
              </div>
            </Card>
          </Col>
        </Row>

        <Row id="RowFeatures" className="mb-3">
          <Col xs={12} className="mx-auto mb-2">
            <div className="mx-auto sectionHeading">
              Features
            </div>
          </Col>
          <Col xs={12} className="mx-auto mb-2">
            <Card
              className=" "
              style={{
                textAlign: "center",
                backgroundColor: "transparent",
                borderBottom: ".5px solid gray"}}>
              <div></div>
            </Card>
          </Col>
          <Row>
            <Col xs={6} className="mt-2 mb-2">
             
              <ul style={{ listStyleType: "circle", fontSize: ".82rem" }}>
                <li>
                  Craft the optimal roster based on player evaluations from
                  tryouts
                </li>
                <li>
                  Construct the optimal roster while adhering to playing time requirements
                </li>
                <li>
                  Formulate the best roster while ensuring appropriate rest for
                  top players
                </li>
                <li>Optimize player distribution across teams</li>
              </ul>
              
            </Col>

            <Col className="mt-2 mb-2">
              <ul style={{ listStyleType: "circle", fontSize: ".82rem" }}>
                <li>Optimize lineups across the season</li>
                <li>
                  Collaborate with parents and coaches to develop conflict-free
                  lineups
                </li>
                <li>
                  Enable centralized lineup building for organizations,
                  independent of team coaches.
                </li>
                <li>
                  Utilize in-game analytics for tactical decisions and assessing
                  probabilities
                </li>
              </ul>
            </Col>
          </Row>

          <Col id="Linup Optimization">
            <Card className="featureCard">
              <Card.Header className="featureCardHeader">
                <PersonCircle className="featureHeaderIcon"></PersonCircle>{" "}
                Lineup Optimization
              </Card.Header>

              <Card.Body>
                <Card.Title className="featureCardTitle">
                  Maximize Team Strength
                </Card.Title>

                <Card.Text className="featureCardText">
                  Craft powerful lineups, balancing playing time and
                  restrictions effortlessly. Choose between the simple basic
                  tool or the advanced lineup tool for optimal team strength.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col id="RosterSelection">
            <Card className="featureCard">
              <Card.Header className="featureCardHeader">
                <Clipboard2Data className="featureHeaderIcon"></Clipboard2Data>{" "}
                Roster Selection
              </Card.Header>

              <Card.Body>
                <Card.Title className="featureCardTitle">
                  Build The Optimal Roster
                </Card.Title>

                <Card.Text className="featureCardText">
                  Leverage player evaluations to build a powerhouse roster,
                  strategically balancing strengths across positions for an
                  unbeatable team synergy.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col id="Fairplay">
            <Card className="featureCard">
              <Card.Header className="featureCardHeader">
                <Shuffle className="featureHeaderIcon"></Shuffle> Fairplay
                Lineups
              </Card.Header>

              <Card.Body>
                <Card.Title className="featureCardTitle">
                  Even Play For Development
                </Card.Title>

                <Card.Text className="featureCardText">
                  Craft lineups for equitable playing time distribution,
                  ensuring every player seizes opportunities across various
                  positions. Achieve balance for a well-rounded team experience.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col id="Additional">
            <Card className="featureCard">
              <Card.Header className="featureCardHeader">
                <Speedometer2 className="featureHeaderIcon"></Speedometer2>{" "}
                Situational Analytics
              </Card.Header>

              <Card.Body>
                <Card.Title className="featureCardTitle">
                  Strategy And Game Insights
                </Card.Title>

                <Card.Text className="featureCardText">
                  Harness impactful insights with specialized analytical tools
                  designed for youth sports. Develop game strategies and make
                  informed in-game decisions for unparalleled success.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row id="ContactRow" className="mb-3">
          <Col xs={12} className="mx-auto mb-2">

          <div className="mx-auto sectionHeading">
                Contact
              </div>
          
          </Col>
          <Col
            xs={12}
            className="mb-2"
            style={{
              textAlign: "center",
              backgroundColor: "transparent",
              borderBottom: ".5px solid gray"
            }}
          ></Col>
          <Col id="ConactTip">
            <Card className="">
              <Card.Header className="contactCardHeader">
                <PersonCircle className="featureHeaderIcon"></PersonCircle>{" "}
                Contact Info
              </Card.Header>

              <Card.Body style={{ textAlign: "left", fontSize: ".83rem" }}>
                <Card.Title className="featureCardTitle">
                  Connect with Team Insights Pro
                </Card.Title>

            
                  <ul style={{ listStyleType: "circle", fontSize: ".82rem" }}>
                    <li> Niche-Analytics LLC</li>
                    <li>Atlanta, GA USA</li>
                    <li> Email: <a href="mailto:support@niche-analytics.com" >support@niche-analytics.com</a></li>
                  </ul>
               
              </Card.Body>
            </Card>
          </Col>
          <Col id="ConactSocial">
            <Card className="">
              <Card.Header className="contactCardHeader">
                <PersonCircle className="featureHeaderIcon"></PersonCircle>{" "}
                Connect With Us
              </Card.Header>

              <Card.Body style={{ textAlign: "left", fontSize: ".83rem" }}>
                <Card.Title className="featureCardTitle">
                  Follow Us On Social Media
                </Card.Title>

            
                  <ul style={{ listStyleType: "circle", fontSize: ".82rem" }}>
                    <li>
                      <Facebook style={{ marginRight: "5px" }} /> <a style={{textDecoration:"none"}}href="https://www.facebook.com/profile.php?id=61554920711201" target="_blank">Facebook</a>
                    </li>
                    <li>
                      <Instagram style={{ marginRight: "5px" }} />
                      <a  style={{textDecoration:"none"}} href="https://www.instagram.com/teaminsightspro/" target="_blank">Instagram</a>
                    </li>
                    <li>
                      <Youtube style={{ marginRight: "5px" }} /><a style={{textDecoration:"none"}} href="www.youtube.com/@TeamInsightsPro" target="_blank"> YouTube</a>
                    </li>
                  </ul>
               
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row id="RowFooter" style={{borderBottom:"20px"}}>
          <Col xs={12}
            style={{
              borderTop: "1px solid black",
              marginTop: "10px",
              textAlign: "center",
              fontSize: ".9rem",
              borderBottom:"20px"
            }}
          >
            <div>
            Copyright &copy; 2024 Niche-Analytics LLC - <Link to="/privacy" style={{textDecoration:"none",marginLeft:"5px"}}>Privacy Policy</Link> - 
            <Link to="/terms" style={{textDecoration:"none"}}> <span style={{marginLeft:"5px"}}>Terms of
            Service</span></Link>
            </div>
          </Col>
          <Col style={{height:"20px"}}>
          </Col>
        </Row>
      </Row>
      <h1 style={{color:"white",fontSize:".1rem"}}>TeamInsightsPro.com Optimize Rosters and Lineups Free</h1>
    </Container>
    
  );}

  export default Aboutus