import React,{useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "./aboutus.css";

import { Link } from "react-router-dom";

import {
  Speedometer2,
  Bootstrap,
  Grid,
  People,
  List,
  Table,
  House,
  Facebook,
  Instagram,
  Bell,
  InfoCircle,
  BoxArrowLeft,
  Youtube,
} from "react-bootstrap-icons";
import {
  PlayBtn,
  BarChart,
  Clipboard2Data,
  Shuffle,
  PersonCheck,
  Lightning,
  Activity,
  PersonFillAdd,
  BoxArrowRight,
  Wallet2,
  PersonCircle,
} from "react-bootstrap-icons";

import ReactGA from 'react-ga4';

const Tutorial = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <Container fluid style={{ backgroundColor: "#f9f9f9" ,marginTop:"15px"}}>
      <Row id="Main Row">
        <Card className="p-0 m-0">
          <Card.Header style={{textAlign:"center",fontSize:"1.5rem",color:"white",backgroundColor:" #4E8542"}}>
            Tutorials
          </Card.Header>
        <Row className="mt-3 mb-3 p-2">
          <Col xs={12} id="heading" >
           
<Card className=" align-items-center p-3" >
<div c style={{ textAlign: "center",fontSize:".95rem"}}>
                Tutorials at varying levels of complexity to guide users on
                getting the most of the all the different tools and analysis
                available in Team Insights Pro.
              </div>
  </Card>
            
            
          </Col>
          <Col>
            <Card className="mt-3 md-4 shadow-sm" style={{ width: "18rem" }}>
              <img
                src="https://placehold.co/305x160/0567AD/FFF?text=Coming+Soon"
                class="card-img-top"
                alt="..."
                style={{
                  border: "0px none",
                  height: "150px",
                  width: "100%",
                  display: "block",
                }}
              />
              <Card.Body>
                <Card.Title style={{ fontSize: "1rem", fontWeight: "bold" }}>
                 Team Insights Pro Overview
                </Card.Title>
                <Card.Text style={{ fontSize: ".87rem" }}>
                  {" "}
                  Create optimal lineups to maximize team strength while
                  balancing playing time requirements.
                </Card.Text>
                <a  class="btn btn-outline-secondary">
                  Watch
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mt-3 md-4 shadow-sm" style={{ width: "18rem" }}>
            <div  style={{
                  border: "0px none",
                  height: "150px",
                  width: "100%",
                  display: "block",
                }}>
                <iframe width="305px"  src="https://www.youtube.com/embed/ddouiONlv8E?si=7rrbhVtrCW_44434" 
                title="Basic Lineup Tutorial" allowFullScreen  style={{
                  border: "0px none",
                  height: "150px",
                  width: "100%",
                  display: "block",
                }}
                ></iframe>
              </div>
              <Card.Body>
                <Card.Title style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Basic Lineup Optimization
                </Card.Title>
                <Card.Text style={{ fontSize: ".87rem" }}>
                  {" "}
                  Create optimal lineups to maximize team strength while
                  balancing playing time requirements.
                </Card.Text>
                <a href="https://www.youtube.com/embed/ddouiONlv8E?si=7rrbhVtrCW_44434" className="btn btn-outline-secondary">
                  Watch
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mt-3 md-4 shadow-sm" style={{ width: "18rem" }}>
            <div >
                <iframe width="305px"  
                src="https://www.youtube.com/embed/nkAhE9fDHKE?si=rXQkMc7UPuXIVvBr" 
                title="Fair Play Tutorial" allowFullScreen style={{
                  border: "0px none",
                  height: "150px",
                  width: "100%",
                  display: "block",
                }}></iframe>
              </div>
              <Card.Body>
                <Card.Title style={{ fontSize: "1rem", fontWeight: "bold" }}>
                Fairplay Lineup Creation
                </Card.Title>
                <Card.Text style={{ fontSize: ".87rem" }}>
                  {" "}
                  Evenly distribute playing time as closely as possible among players on the roster.
                </Card.Text>
                <a href="https://www.youtube.com/embed/nkAhE9fDHKE?si=rXQkMc7UPuXIVvBr" class="btn btn-outline-secondary">
                  Watch
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mt-3 md-4 shadow-sm" style={{ width: "18rem" }}>
            <div className="embed-responsive embed-responsive-16by9">
                  <iframe width="305px"  src="https://www.youtube.com/embed/3QMb5x5UAE8?si=Y0Ry7VxI1i8f02nJ" 
                  title="Roster Selection Tutorial" allowFullScreen  style={{
                    border: "0px none",
                    height: "150px",
                    width: "100%",
                    display: "block",
                  }}></iframe>
                </div>
              <Card.Body>
                <Card.Title style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Roster Selection
                </Card.Title>
                <Card.Text style={{ fontSize: ".87rem" }}>
                  {" "}
                 Select the optimal players for your team based on player evaluations and position needs. Limit total power or salary to meet league requirements.
                </Card.Text>
                <a href="#" class="btn btn-outline-secondary">
                  Watch
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mt-3 md-4 shadow-sm" style={{ width: "18rem" }}>
              <img
                src="https://placehold.co/305x160/0567AD/FFF?text=Coming+Soon"
                class="card-img-top"
                alt="..."
                style={{
                  border: "0px none",
                  height: "150px",
                  width: "100%",
                  display: "block",
                }}
              />
              <Card.Body>
                <Card.Title style={{ fontSize: "1rem", fontWeight: "bold" }}>
                 Advanced Lineup Optimization (Premium)
                </Card.Title>
                <Card.Text style={{ fontSize: ".87rem" }}>
                  {" "}
                  Create optimal lineups and rosters using advanced features and tools not available in Basic Roster Optimization moduele. 
                </Card.Text>
                <a href="#" class="btn btn-outline-secondary">
                  Watch
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mt-3 md-4 shadow-sm" style={{ width: "18rem" }}>
              <img
                src="https://placehold.co/305x160/0567AD/FFF?text=Coming+Soon"
                class="card-img-top"
                alt="..."
                style={{
                  border: "0px none",
                  height: "150px",
                  width: "100%",
                  display: "block",
                }}
              />
              <Card.Body>
                <Card.Title style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Live Draft Roster Selection (Premium)
                </Card.Title>
                <Card.Text style={{ fontSize: ".87rem" }}>
                  {" "}
                  Use optimization tools in real-time draft scenarios. Optimize as players are added and removed from the board.
                </Card.Text>
                <a href="#" class="btn btn-outline-secondary">
                  Watch
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mt-3 md-4 shadow-sm" style={{ width: "18rem" }}>
              <img
                src="https://placehold.co/305x160/0567AD/FFF?text=Coming+Soon"
                class="card-img-top"
                alt="..."
                style={{
                  border: "0px none",
                  height: "150px",
                  width: "100%",
                  display: "block",
                }}
              />
              <Card.Body>
                <Card.Title style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Situational Analytics (Premium)
                </Card.Title>
                <Card.Text style={{ fontSize: ".87rem" }}>
                  {" "}
                  Use multiple sports analytics tools and data to develop and evaluate game scnearios.
                </Card.Text>
                <a href="#" class="btn btn-outline-secondary">
                  Watch
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mt-3 md-4 shadow-sm" style={{ width: "18rem" }}>
              <img
                src="https://placehold.co/305x160/0567AD/FFF?text=Coming+Soon"
                class="card-img-top"
                alt="..."
                style={{
                  border: "0px none",
                  height: "150px",
                  width: "100%",
                  display: "block",
                }}
              />
              <Card.Body>
                <Card.Title style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Using On Mobile
                </Card.Title>
                <Card.Text style={{ fontSize: ".87rem" }}>
                  {" "}
                 How to get the most of the TeamInsights Pro website on mobile devices.
                </Card.Text>
                <a href="#" class="btn btn-outline-secondary">
                  Watch
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </Card>
      </Row>
    </Container>
  );
};
export default Tutorial;
