import React,{useEffect, useState}  from "react";
import LoginForm from "../components/login.component";
import Signup from "../components/signup.component";
import Logout from "../components/logout.component";



const Loginpg = (props) => {
 
  const [email,setEmail]=useState('');
  const [isloggedin, setIsLoggedIn]=useState();





  useEffect ( () =>  {
    
    if (localStorage.getItem('userid')!==null){
      console.log(localStorage.getItem('userid'));
              if(localStorage.getItem('istemp')==='yes'){
                console.log(localStorage.getItem('istemp'));
                setIsLoggedIn(false);
              }
              else
              {
                setIsLoggedIn(true);
                setEmail(localStorage.getItem('email'));
              
              }
  
    }
     
      else
      {   
        setIsLoggedIn(false);
     console.log('User is not logged in');
    }
   


   } ,[])

  return (<div style={{marginTop:'50px'}}>
   
    { isloggedin ? <Logout/> : <LoginForm/>}

   {/*<Footer></Footer>*/}
  </div>
  
  );
};

export default Loginpg;

// The user is <b>{isLoggedIn ? 'currently' : 'not'}</b> logged in.