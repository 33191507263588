import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';




import Pricing from "./pages/pricing_table";
import Loginpg from "./pages/loginpg";
import Register from "./pages/register";
import Tutorial from "./pages/tutorial";
import ResetPassEmailPage from "./pages/resetpassemailpage";
import ResetPassPage from "./pages/resetpass";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Coming from "./pages/comingsoon";

import RosterApp from "./pages/rosterpage";

import RosterLogic from "./components/rosterlogic.component";
import FairPlay from "./components/fairplay.component";
import RosterCreation from "./components/rostercreation.component";

import About from "./pages/about";


import { BrowserRouter, Routes, Route} from "react-router-dom";


import SmoothScroll from "smooth-scroll";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-X88RHVQQQG');



export default function App () {


  return (
    

<BrowserRouter>
      <Routes>
        <Route path="/" element={<RosterApp />}>
          <Route path="tutorial" element={<Tutorial/>}/>
         
          <Route index element={<RosterLogic />} />
          <Route path="fairplay" element={<FairPlay/>}/> 
          <Route path="rostercreation" element={<RosterCreation/>}/> 

          <Route path="comingsoon" element={<Coming/>}/>    
          <Route path="register" element={<Register/>}/>          
          <Route path="loginpg" element={<Loginpg/>}/>
          <Route path="pricing_table" element={<Pricing/>}/>          
         
          <Route path="about" element={<About/>}/>

          <Route path="resetpassemailpage" element={<ResetPassEmailPage/>}/>
          <Route path="resetpass/:tkid" element={<ResetPassPage/>}/>
          <Route path="privacy" element={<Privacy/>}/>
          <Route path="terms" element={<Terms/>}/>         
          </Route>
      
       
      </Routes>
    
          
 </BrowserRouter>

  
   
  );
};

//const root = ReactDOM.createRoot(document.getElementById('root'));
//root.render(<App />);
