import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { useNavigate,Link } from "react-router-dom";

//import './ti_app.css';



 const Coming = () => {
    return(
    <>
  <Row style={{height:"100vh"}} className="">

   <Col  xs={12} style={{height:"85vh"}} className=" d-flex align-items-center justify-content-center">
<Row>
  <Col xs={12} className=" d-flex align-items-center justify-content-center">
  <img style={{height:"75px"}}src="../img/TIPLogo.png"/>
  </Col>
  <Col xs={12} className=" d-flex align-items-center justify-content-center mt-3">
  This Feature Is Still In Development. Come Back Soon.
  </Col>


</Row>



 

 
   </Col>

    <Col xs={12}
            style={{
              borderTop: "1px solid black",
              marginTop: "10px",
              textAlign: "center",
              fontSize: ".9rem",
              borderBottom:"20px"
            }}
          >
            <div>
            Copyright &copy; 2024 Niche-Analytics LLC - <Link to="/privacy" style={{textDecoration:"none",marginLeft:"5px"}}>Privacy Policy</Link> - 
            <Link to="/terms" style={{textDecoration:"none"}}> <span style={{marginLeft:"5px"}}>Terms of
            Service</span></Link>
            </div>
          </Col>
          <Col style={{height:"20px"}}>
          </Col>
        </Row>
        <h1 style={{color:"white",fontSize:".1rem"}}>TeamInsightsPro.com Optimize Rosters and Lineups Free</h1>
         
      
    
        </>
    
    
    )
 }
 export default Coming