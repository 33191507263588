import React,{useEffect, useState}  from "react";
import RosterLogic from "../components/rosterlogic.component";
import uuid from 'react-uuid';
import { Outlet, Link } from "react-router-dom";

//import RosterNavigation from "../components/rosternavigation";
//import 'bootstrap/dist/css/bootstrap.min.css';

//import '../components/v_leftnav.css';
import { BsGear} from "react-icons/bs";
import { BsTwitterX,BsYoutube } from "react-icons/bs";
import { Speedometer2,Bootstrap,Grid,People,List,Table,House,Facebook,Twitter,Instagram,Bell,InfoCircle,BoxArrowLeft} from 'react-bootstrap-icons';
import { PlayBtn,BarChart,Clipboard2Data,Shuffle,PersonCheck,Lightning,Activity,PersonFillAdd,BoxArrowRight,Wallet2,PersonCircle} from 'react-bootstrap-icons';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown'; 
import DropdownButton from 'react-bootstrap/DropdownButton';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Collapse from "react-bootstrap/Collapse";

import './rosterpage.css';


const RosterApp = (props) => {

  const [opensidebar, setOpenSidebar] = useState(true);
  const [userfirstime,setUserFirstTime]=useState(false);
  const [userloggedin, setUserLoggedIn]=useState(false);
  const [userloggedout, setUserLoggedOut]=useState(false);
  const [istempuser,setIsTempUser]=useState();




  //const [usermessage, setUsermessage]=useState("");

//localStorage.removeItem('FirstTimeUser');
//localStorage.removeItem('accessToken');


async function createTempUser(credentials) {
  return  fetch(process.env.REACT_APP_RESTSERVER+'/createtempuser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
  .then(data => data.json())
 
  .catch(error => console.warn(error));
    //.then(data => data.json())
    
 }

 async function loginUser(credentials) {
  return fetch(process.env.REACT_APP_RESTSERVER+'/loginuser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
 }


const tempcreatelogin=async()=>{

  //setEmail(uuid());
             
  //setPwd(generatePassword());
  //setFirstname("tempfirst");
  //setLastname("templast");
 // setPhone("0000");
 // setUsernm("unknown");

      const email=uuid();
      const pwd=generatePassword();
      const firstname="tempfirst";
      const lastname="templast";
      const phone="0000";
      const usernm="unknown";


      const response = await createTempUser({
        email,
        pwd,
        firstname,
        lastname, 
        phone, 
        usernm
      });
          
       //console.log('Here???')

      if (response['added']==='N'){
         // setUsermessage("The email " + response['email'] + " already exists please login or try another");

      }
          else {

              const response2 = await loginUser({
                  email,
                  pwd
                });

                if ('accessToken' in response2) {
                  
              

                    localStorage.setItem('accessToken', response2['accessToken']);
                    localStorage.setItem('refreshToken',response2['refreshToken']);
                    localStorage.setItem('user', response2['email']);
                    localStorage.setItem('userid',response2['userid']);
                    localStorage.setItem('email', response2['email']);

                    //console.log("added new temp user");
                    //console.log(email);
                    //console.log(localStorage.getItem('accessToken'));

                   setUserLoggedIn(true); ///This toggles to cause the rosterlogic component to reload and recongize new logged in temp user
                    
                   ////console.log(localStorage.getItem('accessToken'));

                   //setUsermessage("Welcome "+ response['firstname'] + ". Please proceed to login.");
                  
                   //navigate("/ti_app");
                    //window.location.href = "/";
                  //});
                } else {
                  alert("Failed", response.message, "error");
                }


             
                   // window.location.href = "/loginpg";
          }

      
     // //console.log(response);
    

     

 }

const generatePassword = () => {
    let charset = "";
    let newPassword = "";
    let passwordLength=12;

    charset += "!@#$%^&*()";
    charset += "0123456789";
    charset += "abcdefghijklmnopqrstuvwxyz";
    charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (let i = 0; i < passwordLength; i++) {
        newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }
  return newPassword;
  //setTempPwd(newPassword);
};



const userAnalysis=async()=>{

            ////console.log(localStorage.getItem('FirstTimeUser'));
            if (localStorage.getItem('FirstTimeUser')===null) {
            // alert('Hey First Timer!')
              setUserFirstTime(true);
              localStorage.setItem('FirstTimeUser','no');
            }
            else{
              setUserFirstTime(false);
            
            }


       

              ///create temporary account (userid, email, )
              //display prompt to create account to save work
            
              if (localStorage.getItem('accessToken')===null)
              {
                
              
            
                localStorage.setItem('istempuser','yes');
           
  
                //console.log('Your a nobody');
          
               
  
                tempcreatelogin();
                setIsTempUser(true);

              }
                else{
                const token = localStorage.getItem("accessToken");
                const requestOptions = {
                headers: { "Content-Type": "application/json", Authorization: token },
              };
          
              ////console.log(team);
              const response = await fetch(
                process.env.REACT_APP_RESTSERVER + `/checktoken`,
                requestOptions
              );
              const jsonData = await response.json();
              ////console.log(jsonData);
                        if (jsonData === "Token Expired") {

                          ////console.log('Your a nobody');
            
                          tempcreatelogin();

                        localStorage.setItem('istempuser','yes');
                        setIsTempUser(true);
            
                        }else{
                                    if (jsonData[0].istemp==='1'){
                                      localStorage.setItem('istemp','yes');
                                      //console.log("Temp User Is Logged In")
                                      setIsTempUser(true);

                                    }
                                    else
                                    {
                                      localStorage.setItem('istemp','no')
                                      //console.log("Real User Is Logged In")
                                      setIsTempUser(false)
                                    }
                      
                        }

              }

              
            }
    
    



    useEffect(() => {
      //alert(process.env.REACT_APP_RESTSERVER);

      //userAnalysis();
      //getfreeScenario();
     
    }, []);



  return (
  <div >

<Container fluid style={{backgroundColor:"#f9f9f9"}}>
  <Row>
  <Collapse dimension="width" in={opensidebar}>
    <Col xs={1} id="sidebar"  className="lefter" >
  
         <div>
    <img id="navlogo" src="../img/TI_Logo_4000px_White_NoNA.png" 
    style={{width:"auto",height:"40px",paddingLeft:"25px",paddingRight:"25px",marginTop:"15px",objectFit:"contain"}} />
     
    
                <ul className="nav nav-pills flex-sm-column flex-column flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start leftnav ml-0" id="menu">
                    <li className="nav-item" style={{margin:"2.5"}}>
                     
                            <House/><Link to="/about"  className="px-sm-0 px-2" style={{textDecoration:"none"}}>
                              <span className="ms-1 d-none d-sm-inline">Home</span> </Link>
                       
                    </li>
                    <li className="nav-item" style={{margin:"2.5"}}>
                      
                            <PlayBtn/> <Link to="/tutorial"  className="px-sm-0 px-2" style={{textDecoration:"none"}}>
                              <span className="ms-1 d-none d-sm-inline">Tutorials</span> </Link>
                     
                    </li>
                    <li className="nav-item navgroupheader"><span className="ms-1 d-none d-sm-inline">Analytics</span></li>
                    <hr className="navlinebreak"/>
                    <li className="nav-item" style={{margin:"2.5",marginLeft:"0"}}>
                        
                            <BarChart/>
                            <Link to="/"  className="px-sm-0 px-2" style={{textDecoration:"none"}}>
                              <span className="ms-1 d-none d-sm-inline">Basic Lineup Optimization</span> </Link>
                    </li>
                    <li className="nav-item" style={{margin:"2.5"}}>
                      
                            <PersonCheck/>
                            <Link to="/rostercreation"  className="px-sm-0 px-2" style={{textDecoration:"none"}}>
                             <span className="ms-1 d-none d-sm-inline">Build Roster</span> </Link>
                    </li>
                    <li className="nav-item" style={{margin:"2.5"}}> 
                    
                            <Shuffle/>
                            <Link to="/fairplay"  className="px-sm-0 px-2" style={{textDecoration:"none"}}>
                              <span className="ms-1 d-none d-sm-inline">Fair Play Lineup Creation</span></Link>
                    </li>
                    <li className="nav-item" style={{margin:"2.5"}}><Clipboard2Data/>
                    <Link to="/comingsoon"  className="px-sm-0 px-2" style={{textDecoration:"none"}}>
                            
                            <span className="ms-1 d-none d-sm-inline">Adv. Lineup Optimization</span> </Link>
                    </li>
                    <li className="nav-item" style={{margin:"2.5"}}>
                       
                            <Lightning/>
                            <Link to="/comingsoon"  className="px-sm-0 px-2" style={{textDecoration:"none"}}>
                              <span className="ms-1 d-none d-sm-inline">Live Draft</span></Link>
                          
                    </li>
                    <li className="nav-item" style={{margin:"2.5"}}>
                    
                            <Activity/>
                            <Link to="/comingsoon"  className="px-sm-0 px-2" style={{textDecoration:"none"}}>
                              <span className="ms-1 d-none d-sm-inline">In-Game </span></Link>
                            
                         
                    </li>
                    <li className="nav-item" style={{margin:"2.5"}}>
                      
                            <Speedometer2/>
                            <Link to="/comingsoon"  className="px-sm-0 px-2" style={{textDecoration:"none"}}>
                              <span className="ms-1 d-none d-sm-inline">Situational Analytics</span> 
                          </Link>
                    </li>

                    <li className="nav-item navgroupheader" ><span className="ms-1 d-none d-sm-inline">Account</span></li>
                    <hr className="navlinebreak"/>
                 
                  
                    <li className="nav-item" style={{margin:"2.5",marginLeft:"0px"}}>
                       
                            <PersonFillAdd/>
                            <Link to="/register"  className="px-sm-0 px-2" style={{textDecoration:"none"}}>
                              <span className="ms-1 d-none d-sm-inline">Register</span></Link>
                    </li>

                    
                    <li className="nav-item" style={{margin:"2.5",marginLeft:"0px"}}>
                       
                            <BoxArrowRight/>
                            <Link to="/loginpg"  className="px-sm-0 px-2" style={{textDecoration:"none"}}>
                              <span className="ms-1 d-none d-sm-inline">Sign-In</span></Link>
                    </li>

                    <li className="nav-item" style={{margin:"2.5",marginLeft:"0px"}}>
                       
                            <Wallet2/><Link to="/pricing_table"  className="px-sm-0 px-2" style={{textDecoration:"none"}}>
                              <span className="ms-1 d-none d-sm-inline">Premium Portal</span></Link>
                    </li>

                    <li className="nav-item navgroupheader"><span className="ms-1 d-none d-sm-inline">Other</span></li>
                    <hr className="navlinebreak"/>
                 
                  
                    <li className="nav-item" style={{marginLeft:"0px"}}>
                      
                            <InfoCircle/>
                            <Link to="/about"  className="px-sm-0 px-2" style={{textDecoration:"none"}}>
                            <span className="ms-1 d-none d-sm-inline">About</span></Link>
                    </li>

                    
                    <li className="nav-item">
                     
                            <Grid/>
                            <Link to="https://niche-analytics.com" target="_blank" style={{textDecoration:"none"}}><span className="ms-1 d-none d-sm-inline">Niche-Analytics</span>
                            </Link>
                    </li>

                </ul>
                {/*<div className="navfooter">
                  Currently Not Logged In:
                  Please Login To Save Your Work!
                  </div>*/}
         </div>
        
    </Col>
    
    </Collapse>
    

    <Col id="main" className="main">
      <Row id="mainrow" className="row">

        <Col  id="topbar" className="topbar sticky-top" >
          <Row>
              <Col xsm={1} id="lefttopbarcontent"  className="topbar_leftcolumn" >
                <div style={{display:"flex"}}>
                <Button id="ParamsDiv-header"         onClick={() => setOpenSidebar(!opensidebar)}
                  aria-controls="sidebar"
                  aria-expanded={opensidebar} style={{backgroundColor:"white",height:"30px",borderStyle:"none",padding:"0"}}>
                {opensidebar ? <BoxArrowLeft id="lbleft" className="leftbarcloseicon"/>:
                <BoxArrowRight id="lbright" className="leftbarcloseicon" /> }
                
           
                </Button>
                <Dropdown id="respNav">
                  <Dropdown.Toggle  className="leftbardropdownmenuicon">
                   Menu
                     {/* <List style={{color:"blue",fontSize:"1.5rem",fontWeight:"bolder",marginLeft:"8px"}}/>*/}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="leftmenudropdowncontents">
                    
                    <Dropdown.Item href="https://google.com">
                    <House/><Link to="/about"  className="px-sm-0 px-2" style={{textDecoration:"none",color:"white"}}>Home
                              </Link>
                     </Dropdown.Item>
                      <Dropdown.Item> 
                      <PlayBtn/> <Link to="/tutorial"  className="px-sm-0 px-2" style={{textDecoration:"none",color:"white"}}>Tutorials
                              </Link>
                     </Dropdown.Item>
                     <Dropdown.Item> 
                       <BarChart/><Link to="/"  className="px-sm-0 px-2" style={{textDecoration:"none",color:"white"}}>
                        Basic Lineup Optimization </Link>
                     </Dropdown.Item>
                     <Dropdown.Item> 
                     <PersonCheck/>
                            <Link to="/rostercreation"  className="px-sm-0 px-2" style={{textDecoration:"none",color:"white"}}>
                             Build Roster </Link>
                     </Dropdown.Item>
                     <Dropdown.Item> 
                     <Shuffle/>
                            <Link to="/fairplay"  className="px-sm-0 px-2" style={{textDecoration:"none",color:"white"}}>
                             Fair Play Lineup Creation</Link>
                     </Dropdown.Item>
                     <Dropdown.Item> 
                     <Clipboard2Data/>
                    <Link to="/comingsoon"  className="px-sm-0 px-2" style={{textDecoration:"none",color:"white"}}>
                         Adv. Lineup Optimization</Link>
                     </Dropdown.Item>
                     <Dropdown.Item> 
                     <Lightning/>
                            <Link to="/comingsoon"  className="px-sm-0 px-2" style={{textDecoration:"none",color:"white"}}>
                             Live Draft</Link>
                     </Dropdown.Item>
                     <Dropdown.Item> 
                     <Activity/>
                            <Link to="/comingsoon"  className="px-sm-0 px-2" style={{textDecoration:"none",color:"white"}}>
                              In-Game</Link>
                     </Dropdown.Item>
                     <Dropdown.Item> 
                     <Speedometer2/>
                            <Link to="/comingsoon"  className="px-sm-0 px-2" style={{textDecoration:"none",color:"white"}}>
                            Situational Analytics </Link>
                     </Dropdown.Item>
                     <Dropdown.Item> 
                     <PersonFillAdd/>
                            <Link to="/register"  className="px-sm-0 px-2" style={{textDecoration:"none",color:"white"}}>
                             Register</Link>
                     </Dropdown.Item>
                     <Dropdown.Item> 
                     <BoxArrowRight/>
                            <Link to="/loginpg"  className="px-sm-0 px-2" style={{textDecoration:"none",color:"white"}}>
                            Sign-In</Link>
                     </Dropdown.Item>  
                     <Dropdown.Item> 
                     <Wallet2/><Link to="/pricing_table"  className="px-sm-0 px-2" style={{textDecoration:"none",color:"white"}}>
                             Premium Portal</Link>
                     </Dropdown.Item> 
                     <Dropdown.Item> 
                     <InfoCircle/>
                            <Link to="/about"  className="px-sm-0 px-2" style={{textDecoration:"none",color:"white"}}>
                           About</Link>
                     </Dropdown.Item> 
                     <Dropdown.Item> 
                     <Grid/>
                            <Link to="https://niche-analytics.com" target="_blank" style={{textDecoration:"none",color:"white"}}>
                             Niche-Analytics </Link> 
                      </Dropdown.Item> 
                   </Dropdown.Menu>
                </Dropdown>
                </div>
              </Col>

          
              <Col className="topbar_rightcolumn" >
              <div className="topiconholder" >
                     <a href="https://www.facebook.com/profile.php?id=61554920711201" target="_blank" style={{textDecoration:"none"}}>
                            <Facebook className="topicons" /> </a>
                     <a href="https://www.instagram.com/teaminsightspro/" target="_blank" style={{textDecoration:"none"}}>
                            <Instagram className="topicons" style={{color:"brown"}}/></a>
                      <a href="https://twitter.com/TeamInsigh13730" target="_blank" style={{textDecoration:"none"}}>
                            <BsTwitterX className="topicons" style={{color:"black"}}/></a>
                      <a href="https://www.youtube.com/@TeamInsightsPro" target="_blank" style={{textDecoration:"none"}}>
                            <BsYoutube className="topicons" style={{color:"DarkRed"}}/></a>
                            

                      <Link to="/loginpg" style={{textDecoration:"none"}}>
                           {istempuser ?   <PersonCircle className="topicons" style={{color:"darkred",fontSize:"1.6rem"}}/>:<PersonCircle className="topicons" style={{color:"green",fontSize:"1.6rem"}}/>}</Link>
                  
                </div>
              </Col>
            </Row>
         
        </Col>

      {/*}  <Col xs={12} className=" params">
          <Row className="row">
          <Col className="d-flex justify-content-center" >
            <Card style={{margin:"5px",fontSize:".9rem"}} >
              <Card.Header style={{padding:"0",fontSize:".8rem",textAlign:"center"}}>
            Each Player Must Play:
              </Card.Header >
              <div >
              <label id="MinPTlabel" className="inputLabels" style={{margin:"5px"}}>Min:
               <input id="inputMinPT" className="inputBox" type='number' 
                                    min="0" max="100" style={{margin:"5px"}}/>%</label>
              <label id="MaxPTlable" className="inputLabels" style={{margin:"5px"}}>Max:
               <input id="inputMaxPT" className="inputBox"  type='number' style={{margin:"5px"}}
                                min="0" max="100" 
                                />%</label>
              </div>
                
            </Card>
          </Col>
            
          <Col className=" d-flex justify-content-center">
                  <Card style={{margin:"5px",fontSize:".8rem"}} >
              <Card.Header style={{padding:"0",paddingRight:"5px",paddingLeft:"5px",fontSize:".8rem",textAlign:"center"}}>
                Lineups Needed:
              </Card.Header>
              <input id="inputMinPT" className="inputBox" type='number' 
                                    min="0" max="100" style={{margin:"5px",width:"55px"}}/>
            </Card>
          </Col>
          </Row>
  </Col>*/}
          
        <Col  xs={12} className="logic" >
<div>
  <Outlet/>
     {/* {userloggedin ? <RosterLogic/> : <></>} 
      {userloggedout ?  <></> : <RosterLogic/> } 
*/}
     
       </div>
          </Col>

        </Row>
      </Col>
      
    </Row>
    
<h1 style={{color:"white",fontSize:".1rem"}}>TeamInsightsPro.com Optimize Rosters and Lineups Free</h1>
  </Container>

  <div>
    </div>

    </div>
  );
};

export default RosterApp;
