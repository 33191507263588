import React, { useEffect, useState} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import '../App.css';
import './LoginForm.css'


export default function ResetPassSendEmail() {

  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [validateLink, setValidateLink]=useState("");
  const navigate=useNavigate();

  const handleSubmit = async e => {
    e.preventDefault();

    const requestOptions = {
      headers: { 'Content-Type': 'application/json' }
    };



    const response=await  fetch(process.env.REACT_APP_RESTSERVER+`/ResetTokenCreate/${email}`,requestOptions)
    const jsonData= await response.json();
    //console.log(jsonData);
    setToken(jsonData);

    setEmail(email);
    
  }

useEffect(()=>{
  
  //setValidateLink(`http://localhost:3000/resetpass/`+token)
  //setValidateLink('Check Email For Password Reset Link (Make sure to look in spam and Junk Folders)');
  if (token===""){
    setValidateLink("");
  }
  else{
  setValidateLink('Check Email For Password Reset Link (Make sure to look in spam and Junk Folders)');
  setEmail('');
  setTimeout(() => {
    //setShowDelayedText(true);
    navigate("/");
}, 3000);
  
  }

},[token])

return(
    
        <>
          
        <div style={{margin:"auto",height:"100%",backgroundImage:"linear-gradient(white, #F7f7f9",textAlign:"center"}}>
    
    
         <div id="login-form">
          
    
         <form>
            <h2 >Enter Email To Reset Password</h2>
                <label htmlFor="username">Email:</label>
    
                <input type="text" id="email"   value={email} 
            onChange={e => setEmail(e.target.value)}/>
               
    
    
    
                  <input type="submit" value="Submit" onClick={handleSubmit}/>
                  <div id="fp">
                 
                  
                  </div>
                  <p> <span style={{marginTop:'20px'}}><Link to="/loginpg">Return To Login</Link> </span></p>
                  <p> <span style={{marginTop:'20px'}}>{validateLink} </span></p>
               
           
              
          </form>
                
          <div></div>
          {/*
              <div>Welcome: {email2} </div>
              <div>Token: {aToken}</div>
                <div>RToken: {rToken}</div>
              <div>{fail}</div>
                */}
             </div>
             
             </div>
            
             </>
);


}