import React, {
    useCallback,
    useEffect,
    useState,
    useRef,
    Component,
  } from "react";
  
  import Button from "react-bootstrap/Button";
  import Collapse from "react-bootstrap/Collapse";
  import Container from "react-bootstrap/Container";
  import Modal from 'react-bootstrap/Modal';
  import Form from 'react-bootstrap/Form';
  
  import Row from "react-bootstrap/Row";
  import Col from "react-bootstrap/Col";
  import Card from "react-bootstrap/Card";
  import { useNavigate,Link } from "react-router-dom";
  
  import { AgGridReact } from "ag-grid-react";
  
  
  import {Bar} from "react-chartjs-2";
  import {Chart as ChartJS} from "chart.js/auto";
 
  
  import uuid from 'react-uuid';
  
  
  import {
    BsGear,
    BsFillArrowRightCircleFill,
    BsFillArrowLeftCircleFill,
    BsFillPersonFill,
    BsGridFill,
    BsPlusSlashMinus,
    BsPersonLinesFill,
    BsTrash3Fill,
    BsFillPlusCircleFill,
  } from "react-icons/bs";
  
  import { PlayBtn,PersonFillAdd,PersonCircle,PersonCheck,PlayFill} from 'react-bootstrap-icons';
  
  
  import { FiShare } from "react-icons/fi";
  import { TiDelete } from "react-icons/ti";
  
  import "ag-grid-community/styles/ag-grid.css";
  import "ag-grid-community/styles/ag-theme-alpine.css";
  
  //import './ti_app_header.css'
  import "../components/rosterlogic.css";
  
  import { Placeholder } from "react-bootstrap";

  import ReactGA from 'react-ga4';
  
  const RosterCreation = (props) => {
    const navigate = useNavigate();
  
    //Get Logged In Userid
    //const userid=[{id:localStorage.getItem('userid')}];
    //const ider=userid[0];
  
  
    //if (localStorage.getItem("userid") === null) {
      //navigate("/loginpg");
      ////window.location.href = "/loginpg";
    //}
  
    //const [userid,setUserid]=useState();
    const userid = [{ id: localStorage.getItem("userid") }];
    const ider = userid[0];
    ////console.log(ider.id);
   // const [userid, setUserid]=useState([]);
  //  const [ider,setIder]=useState('');
    const [team, setTeam] = useState(0);
    const [teamname, setTeamname] = useState("");
    const [teamrowdata, setTeamrowdata] = useState([]);
    const [freename, setFreename] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [positionname, setPosition] = useState("");
    const [newplayeradded, setNewplayeradded] = useState([]);
    const [newpositionadded, setNewPositionadded] = useState([]);
    const [positionid, setPositionid] = useState([]);
    const [playerid, setPlayerid] = useState();
    const [playerRowdata, setPlayerRowdata] = useState([]);
    const [positionRowdata, setPositionrowdata] = useState([]);
    const [ratingRowdata, setRatingrowdata] = useState([]);
    const [playername, setPlayerName] = useState();
    const [ratingcategory, setRatingCategory] = useState("");
    const [lineupmin, setLineupMin] = useState(0);
    const [lineupmax, setLineupMax] = useState(0);
    const [playpermin, setPlayPerMin] = useState(0);
    const [playpermax, setPlayPerMax] = useState(0);
    const [scenariofreeid, setScenarioFreeID] = useState(0);
    const [resultrowdata, setResultRowData] = useState([]);
    const [pagecurrentlineup, setPageCurrentLineup] = useState(0);
    const [lineupcount, setLineupCount] = useState(0);
    const [solvemessage, setSolveMessage] = useState("No Lineups Created");
    const [objectives, setObjectives] = useState([]);
    const [maxvalue, setMaxValue] = useState(0);
    const [dbMax, setDbMax] = useState(0);
    const [lineupflag, setLineupFlag]=useState(0);
    const [playercount, setPlayerCount]=useState(0);
    const [maxvalue2deep,setMaxValue2deep]=useState(0);
    const [fantasymode,setFantasyMode]=useState(0);
    const [fantasychecked,setFantasyChecked]=useState(false);
   
  
    const [openparams, setOpenParams] = useState(true);
    const [openplayers, setOpenPlayers] = useState(true);
    const [openpositions, setOpenPositions] = useState(true);
    const [openratings, setOpenRatings] = useState(true);
    const [openlineups, setOpenLineups] = useState(true);
  
    const [isloggedin, setIsLoggedIn] = useState();
  
    const gridRef2 = useRef(); //Player Grid
    const gridRef3 = useRef(); //Position Grid
    const gridRef4 = useRef(); //Rating Grid
    const gridRef5 = useRef(); //result grid
  
    const [istempuser,setIsTempUser]=useState();
    const [userfirstime,setUserFirstTime]=useState(false);
    const [userloggedin, setUserLoggedIn]=useState(false);
    const [userloggedout, setUserLoggedOut]=useState(false);
    const [usermanaged, setUserManaged]=useState(true);
    const[svwrk,setSvwrk]=useState(true);
    const [msgname, setMesgname]=useState('');
   const [modalshow,setModalshow]=useState(false);
    
   const handleModalClose = () => setModalshow(false);
   const handleModalShow = () => setModalshow(true);
  
   const [modalDemoshow,setModalDemoshow]=useState(false);
  
   const handleModalDemoClose = () => setModalDemoshow(false);
   const handleModalDemoShow = () => setModalDemoshow(true);
  
  const userAnalysis=async()=>{
  
            const generatePassword = () => {
              let charset = "";
              let newPassword = "";
              let passwordLength=12;
          
              charset += "!@#$%^&*()";
              charset += "0123456789";
              charset += "abcdefghijklmnopqrstuvwxyz";
              charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          
              for (let i = 0; i < passwordLength; i++) {
                  newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
              }
            return newPassword;
            //setTempPwd(newPassword);
          };
  
            const tempcreatelogin=async()=>{
            
              //setEmail(uuid());
                        
              //setPwd(generatePassword());
              //setFirstname("tempfirst");
              //setLastname("templast");
            // setPhone("0000");
            // setUsernm("unknown");
            
                  const email=uuid();
                  const pwd=generatePassword();
                  const firstname="tempfirst";
                  const lastname="templast";
                  const phone="0000";
                  const usernm="unknown";
            
            
                  const response = await createTempUser({
                    email,
                    pwd,
                    firstname,
                    lastname, 
                    phone, 
                    usernm
                  });
                      
                  //console.log('Here???')
            
                  if (response['added']==='N'){
                    // setUsermessage("The email " + response['email'] + " already exists please login or try another");
            
                  }
                      else {
            
                          const response2 = await loginUser({
                              email,
                              pwd
                            });
            
                            if ('accessToken' in response2) {
                              
                          
            
                                localStorage.setItem('accessToken', response2['accessToken']);
                                localStorage.setItem('refreshToken',response2['refreshToken']);
                                localStorage.setItem('user', response2['email']);
                                localStorage.setItem('userid',response2['userid']);
                                localStorage.setItem('email', response2['email']);
                                localStorage.setItem('istemp','yes');
            
                                //console.log("added new temp user");
                                //console.log(email);
                                //console.log(localStorage.getItem('accessToken'));
            
                              setUserLoggedIn(true); ///This toggles to cause the rosterlogic component to reload and recongize new logged in temp user
                              setUserManaged(3);
                        
  
                              ////console.log(localStorage.getItem('accessToken'));
            
                              //setUsermessage("Welcome "+ response['firstname'] + ". Please proceed to login.");
                              
                              //navigate("/ti_app");
                                //window.location.href = "/";
                              //});
                            } else {
                              alert("Failed", response.message, "error");
                            }
            
            
                        
                              // window.location.href = "/loginpg";
                      }
            
                  
                // //console.log(response);
                
            
                
            
            }
    
          async function createTempUser(credentials) {
            return  fetch(process.env.REACT_APP_RESTSERVER+'/createtempuser', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(credentials)
            })
            .then(data => data.json())
          
            .catch(error => console.warn(error));
              //.then(data => data.json())
              
          }
    
          async function loginUser(credentials) {
            return fetch(process.env.REACT_APP_RESTSERVER+'/loginuser', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(credentials)
            })
              .then(data => data.json())
          }
  
  
  
      ////console.log(localStorage.getItem('FirstTimeUser'));
      if (localStorage.getItem('FirstTimeUser')===null) {
      // alert('Hey First Timer!')
        setUserFirstTime(true);
        localStorage.setItem('FirstTimeUser','no');
        //console.log('first time doing it')
       
      }
      else{
        setUserFirstTime(false);
        //console.log('done it before')
       
      
      }
  
  
  
  
        ///create temporary account (userid, email, )
        //display prompt to create account to save work
      
        if (localStorage.getItem('accessToken')===null)
        {
          
        
      
          localStorage.setItem('istempuser','yes');
     
  
          //console.log("You're a nobody");
    
         
  
          tempcreatelogin();
          setIsTempUser(true);
          
  
        }
          else{
          const token = localStorage.getItem("accessToken");
          const requestOptions = {
          headers: { "Content-Type": "application/json", Authorization: token },
        };
    
        ////console.log(team);
        const response = await fetch(
          process.env.REACT_APP_RESTSERVER + `/checktoken`,
          requestOptions
        );
        const jsonData = await response.json();
        ////console.log(jsonData);
                  if (jsonData === "Token Expired") {
  
                    ////console.log('Your a nobody');
      
                    tempcreatelogin();
  
                  localStorage.setItem('istempuser','yes');
                  setIsTempUser(true);
      
                  }else{
                              if (jsonData[0].istemp==='1'){
                                localStorage.setItem('istemp','yes');
                                //console.log("Temp User Is Logged In")
                                setIsTempUser(true);
                                setUserManaged(1);
                          
                               
  
                              }
                              else
                              {
                                localStorage.setItem('istemp','no')
                                //console.log("Real User Is Logged In")
                                setIsTempUser(false)
                                setUserManaged(2);
                         
                               
                              }
                
                  }
  
        }
  
        
      }
  
      const handleSwitchChange = (e) => {
        // When the switch is true (ON), set lineupFlag to 0
        // When the switch is false (OFF), set lineupFlag to 1
        //setFantasyMode(e.target.checked ? 1 : 0);
        setFantasyChecked(e.target.checked);
        ////console.log(fantasymode);
      };
   
  
    ///ChartJS States
    const [barchartpower, setBarChartPower]=useState(
      {labels:[],
      datasets:[{label:"Position Strength",
      data:[]}]}
      );
    
    


    const [barchartplayer, setBarChartPlayer]=useState(
        {labels:[],
        datasets:[{label:"Player Strength",
        data:[]}]}
        );

        const [barchartlineuppower, setBarChartLineupPower]=useState(
          {labels:[],
          datasets:[{label:"Lineup Strength",
          data:[]}]}
          );

    const [barchartplayeridst, setBarChartPlayerdist]=useState(
      {labels:[],
        datasets:[{label:"Lineups Per Player",backgroundColor:'rgba(129, 133, 137, 0.5)',
        data:[]}]}
     )
    
     const [barchartposition, setBarChartPosition]=useState(
      {labels:[],
        datasets:[{label:"Strength By Position",
        data:[]}]}
     )
  
  
    const defautColDefPlayers = {
      sortable: false,
      editable: true,
  
      filter: false,
      floatingFilter: false,
      cellStyle: {
        textAlign: "left",
      },
    };
  
    //Player Grid
    const columnDefsPlayers = [
      { headerName: "Player ID", field: "playerid", hide: true },
      {
        headerName: "Name",
        field: "freename",
        flex: 1,
        sort: "asc",
        tooltipField: "freename",
      },
      {
        headerName: "Delete",
        field: "playerid",
        width: 30,
        minWidth: 5,
        maxWidth: 30,
        cellRenderer: function (params) {
          return (
            <button
              onClick={() => {
                deletePlayer(params);
              }}
              type="button"
              className="griddeletebutton"
            >
              <TiDelete />
            </button>
          );
        },
        editable: false,
      },
    ];
  
    const defautColDefPositions = {
      sortable: false,
      editable: true,
  
      filter: false,
      floatingFilter: false,
      cellStyle: {
        textAlign: "left",
      },
    };
  
    //Position Grid Headers
    const columnDefsPositions = [
      { headerName: "teampositionid", field: "teampositionid", hide: true },
      { headerName: "position", field: "positionname", flex: 1 },
      {
        headerName: "Delete",
        field: "teampositionid",
        width: 30,
        minWidth: 5,
        maxWidth: 30,
        cellRenderer: function (params) {
          return (
            <button
              onClick={() => {
                deletePosition(params);
              }}
              className="griddeletebutton"
            >
              <TiDelete />
            </button>
          );
        },
        editable: false,
      },
    ];
  
    const defautColDefRatings = {
      sortable: false,
      editable: true,
      filter: false,
      floatingFilter: false,
      cellStyle: {
        textAlign: "left",
      },
    };
  
    //RatingGrid
    const columnDefsRatings = [
      { headerName: "Rating ID", field: "teamplayerratingid", hide: true },
      { headerName: "Name", field: "freename", editable: false, flex: 1 },
      { headerName: "Position", field: "positionname", editable: false, flex: 1 },
      {
        headerName: "Rating",
        field: "rating",
        width: 40,
        editable: true,
        cellClass: "cusEditCell",
        cellStyle: {
          border: "solid .3px",
          borderRadius: "3px",
          marginTop: "2px",
          marginTop: "2px",
          height: "18px",
          borderColor: "gray",
          cursor: "text",
          fontSize:"3px !important"
        },
      },
    ];
  
    const columnDefsResults = [
      {
        headerName: "Scenario ID",
        field: "scenarioid",
        width: 0,
        minWidth: 0,
        hide: true,
      },
      {
        headerName: "Segment",
        field: "segment",
        width: 40,
        hide: true,
        sort: "asc",
      },
      //{ headerName: "Position", field: "positionname", sort: "asc", flex: 1 },

      {
        headerName: "First Name",
        field: "firstname",
        width: 100,
        hide: true,
        width: 0,
        minWidth: 0,
      },
      {
        headerName: "Last Name",
        field: "lastname",
        width: 100,
        hide: true,
        width: 0,
        minWidth: 0,
      },
      { headerName: "Rank", field: "rnk", flex: 1},
      { headerName: "Name", field: "freename", width: 140, minWidth: 140},
      { headerName: "Impact", field: "impact", flex: 1},
      { headerName: "Rating", field: "rating", flex:1 },
      { headerName: "Status", field: "teamrole",flex:1},
      
    ];
  
    const defautColDefResults = {
      sortable: false,
      editable: false,
  
      filter: false,
      floatingFilter: false,
      cellStyle: {
        textAlign: "left",
      },
    };
  
const expireUser = async () => {
  localStorage.removeItem("email");
  localStorage.removeItem("userid");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("istemp");
  ////console.log('loggedOut');
};

const deletePlayer = async (params) => {
  try {
    const token = localStorage.getItem("accessToken");
    const uid=parseInt(localStorage.getItem('userid'));
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json", Authorization: token },
      body: JSON.stringify({
        userid: uid,
        teamid: team,
        playerid: params.value,
      }),
    };

    ////console.log(requestOptions.body)
    const response = await fetch(
      process.env.REACT_APP_RESTSERVER + "/deleteplayer",
      requestOptions
    );
    //setNewteamadded([{time:Date()}]);
    setNewplayeradded([{ time: Date() }]);
  } catch (err) {
    console.error(err.message);
  }
};

const onPlayerSelectionChanged = useCallback(() => {
  gridRef3.current.api.deselectAll(); //clear selected rows from position table because player was selected

  if (gridRef2.current.api.getSelectedRows().length > 0) {
    const selectedRows = gridRef2.current.api.getSelectedRows();
    setPlayerid(selectedRows[0].playerid);
    // setPlayerName(selectedRows[0].freename);
    setPositionid(0);
    setRatingCategory(selectedRows[0].freename);
    gridRef4.current.columnApi.setColumnsVisible(["freename"], false); //hiding name column because it is selected and displayed on top so only nned to show positions
    gridRef4.current.columnApi.setColumnsVisible(["positionname"], true);
    //gridRef3.current.api.deselectAll(); //clear selected rows from position table because player was selected

    ////console.log('Player Changed');
  }
}, []);

const onPositionSelectionChanged = useCallback(() => {
  gridRef2.current.api.deselectAll();
  if (gridRef3.current.api.getSelectedRows().length > 0) {
    const selectedRows = gridRef3.current.api.getSelectedRows();

    setPositionid(selectedRows[0].teampositionid);

    //setPosition(selectedRows[0].positionname);
    setPlayerid(0);
    setRatingCategory(selectedRows[0].positionname);

    gridRef4.current.columnApi.setColumnsVisible(["positionname"], false); //hiding name column because it is selected and displayed on top so only nned to show positions
    gridRef4.current.columnApi.setColumnsVisible(["freename"], true); //hiding name column because it is selected and displayed on top so only nned to show positions

    //gridRef2.current.api.deselectAll(); //clear selected rows from position table because player was selected

    ////console.log('Position Changed');
  }
}, []);

const updateTeam = async (e) => {
  e.preventDefault();

  const token = localStorage.getItem("accessToken");

  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", Authorization: token },
    body: JSON.stringify({
      userid: ider.id,
      teamname: teamname,
      sportname: "",
      teamid: team,
    }),
  };
  const updateteamcall = async () => {
    const response = await fetch(
      process.env.REACT_APP_RESTSERVER + "/updateteam",
      requestOptions
    );
    const jsonData = await response.json();
  };

  updateteamcall();

  ////console.log(
  //'team name changed'
  //);
};

const updatePlayer = useCallback((event) => {
  var data = event.data;

  //const uid = parseInt(ider.id, 10);
  const uid=parseInt(localStorage.getItem('userid'));
  const token = localStorage.getItem("accessToken");

  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", Authorization: token },
    body: JSON.stringify({
      userid: uid,
      teamid: data.teamid,
      freename: data.freename,
      playerid: data.playerid,
    }),
  };
  const updateplayercall = async () => {
    const response = await fetch(
      process.env.REACT_APP_RESTSERVER + "/updatefreeplayer",
      requestOptions
    );
    const jsonData = await response.json();
    setNewplayeradded([{ time: Date.now() }]);
  };

  updateplayercall();

  ////console.log('onCellValueChanged: ' + event.colDef.field + ' = ' + event.newValue );
}, []);

const updatePosition = useCallback((event) => {
  var data = event.data;

  //const uid = parseInt(ider.id, 10);
  //const uid=parseInt(localStorage.getItem('userid'));
  const uid=parseInt(localStorage.getItem('userid'));
  const token = localStorage.getItem("accessToken");

  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", Authorization: token },
    body: JSON.stringify({
      userid: uid,
      teamid: data.teamid,
      positionname: data.positionname,
      teampositionid: data.teampositionid,
    }),
  };
  const updatepositioncall = async () => {
    const response = await fetch(
      process.env.REACT_APP_RESTSERVER + "/updateposition",
      requestOptions
    );
    const jsonData = await response.json();
    setNewPositionadded([{ time: Date.now() }]);
  };

  updatepositioncall();

  // //console.log('onCellValueChanged: ' + event.colDef.field + ' = ' + event.newValue );
}, []);

useEffect(() => {
  //alert(process.env.REACT_APP_RESTSERVER);
  userAnalysis();



  //getObjectives(); //Moved These into the userAnalysis function 
  //getfreeTeam();
  //console.log(localStorage.getItem('istemp'));
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  //getfreeScenario();
}, []);

useEffect(() => {
  ////console.log(localStorage.getItem('accessToken'));

  if (team > 0) {
    getplayers();
    getpositions();
    getfreeScenario();
  
    
  }
}, [team]);

useEffect(() => {
  if (team > 0) {
    //getplayers();
    getpositions();
    //getfreeScenario();
  }
}, [newpositionadded]);

useEffect(() => {
  if (team > 0) {
    getplayers();
  }
}, [newplayeradded]);

useEffect(() => {
  if (team > 0 && playerid > 0) {
    getRatingsByPlayer();
  }
}, [playerid]);
  
useEffect(() => {
      if (team > 0 && positionid > 0) {
        getRatingsByPosition();
      }
    }, [positionid]);
  
useEffect(() => {
      //alert(process.env.REACT_APP_RESTSERVER);
  
      getObjectives(); //Moved These into the userAnalysis function 
      getfreeTeam();
      //console.log(localStorage.getItem('istemp'));
  
      //getfreeScenario();
    }, [usermanaged]);
  
useEffect(() => {
      //alert(process.env.REACT_APP_RESTSERVER);
  
      getResults();
  
      //getfreeScenario();
    }, [scenariofreeid]);
  
useEffect(() => {
      //alert(process.env.REACT_APP_RESTSERVER);
  
      //setFantasyMode(fantasychecked ? 1 : 0)
      //console.log(fantasychecked);
      
      //getfreeScenario();
    }, [fantasychecked]);  
  
const getObjectives = async () => {
      const response = await fetch(
        process.env.REACT_APP_RESTSERVER + `/getobjectives`
      );
      const jsonData = await response.json();
      setObjectives(jsonData);
      ////console.log(jsonData);
    };
  
const getplayers = async () => {
      const token = localStorage.getItem("accessToken");
      const requestOptions = {
        headers: { "Content-Type": "application/json", Authorization: token },
      };
  
      ////console.log(team);
      const response = await fetch(
        process.env.REACT_APP_RESTSERVER + `/getplayers/${team}`,
        requestOptions
      );
      const jsonData = await response.json();
  
      if (jsonData === "Token Expired") {
        expireUser();
      } else {
        setPlayerRowdata(jsonData);
        //console.log(playerRowdata);
        
  
        //populate data for playe bar chart
           ///populating power bar chart
           
           const chartresponse = await fetch(
            process.env.REACT_APP_RESTSERVER + `/getresultsplayerchart/${team}`,
            requestOptions
          );
         
          const chartjsonData = await chartresponse.json();
          //console.log(chartjsonData);
          if (chartjsonData==="Unauthorized Access"){
            //console.log("oops");
          }else{
            setBarChartPlayer({
              labels:chartjsonData.map((data)=>data.freename),
              datasets:[{
                  label:"Average Player Strength",
                  data:chartjsonData.map((data)=>data.averagerating),
                  backgroundColor:" #7FB069",
                
                    }]
    
                 
            })
          }
         
  
          //console.log(barchartplayer);
  
  
  
        ////console.log(jsonData);
      }
    };
  
const getfreeTeam = async () => {
      ////console.log('Here in free teamer');
      const token = localStorage.getItem("accessToken");
      const requestOptions = {
        headers: { "Content-Type": "application/json", Authorization: token },
      };
  
      const response = await fetch(
        process.env.REACT_APP_RESTSERVER + `/getfreeteam/${ider.id}`,
        requestOptions
      );
      const jsonData = await response.json();
      //setTeamrowdata(jsonData)
      //const firstRow = jsonData[0];
      // const firstColumn = firstRow.teamid;
  
      if (jsonData === "Token Expired") {
        expireUser();
      } else {
        setTeam(jsonData[0].teamid);
        setTeamname(jsonData[0].teamname);
        ////console.log('Grid Is Ready BBXX' );
        ////console.log(team);
        ////console.log('Name ' );
      }
    };
  
const getfreeScenario = async () => {
      ////console.log('Here in free scenarioer');
      ////console.log(ider.id);
  
      const token = localStorage.getItem("accessToken");
      const requestOptions = {
        headers: { "Content-Type": "application/json", Authorization: token },
      };
  
      const response = await fetch(
        process.env.REACT_APP_RESTSERVER + `/getfreescenario/${team}`,
        requestOptions
      );
      const jsonData = await response.json();
  
      if (jsonData === "Token Expired") {
        expireUser();
      } else {
        //setTeamrowdata(jsonData)
        const firstRow = jsonData[0];
        //const firstColumn = firstRow.teamid;
        //setTeam(firstColumn);
        //setTeamname(firstRow.teamname);
        setScenarioFreeID(jsonData[0].scenarioid);
        //setLineupMin(jsonData[0].lineupsmin);
        setPlayerCount(jsonData[0].ros_playercount);
        //setPlayPerMax(jsonData[0].playpermax * 100);
        //setPlayPerMin(jsonData[0].playpermin * 100);
        setMaxValue(jsonData[0].maxvalue);
  
        ////console.log(jsonData[0].lineupsmin,jsonData[0].lineupsmax)
  
        ////console.log('Got Scenario Data' );
        ////console.log(teamname);
      }
    };
  
const getRatingsByPlayer = async () => {
      const token = localStorage.getItem("accessToken");
      const requestOptions = {
        headers: { "Content-Type": "application/json", Authorization: token },
      };
  
      const response = await fetch(
        process.env.REACT_APP_RESTSERVER +
          `/getplayerratingbyplayer/${team}/${playerid}`,
        requestOptions
      );
      const jsonData = await response.json();
      if (jsonData === "Token Expired") {
        expireUser();
      } else {
        setRatingrowdata(jsonData);
        // //console.log(jsonData);
        //gridRef2.current.api.refreshCells();
      }
    };
  
const getRatingsByPosition = async () => {
      const token = localStorage.getItem("accessToken");
      const requestOptions = {
        headers: { "Content-Type": "application/json", Authorization: token },
      };
  
      const response = await fetch(
        process.env.REACT_APP_RESTSERVER +
          `/getplayerratingbyposition/${team}/${positionid}`,
        requestOptions
      );
      const jsonData = await response.json();
      if (jsonData === "Token Expired") {
        expireUser();
      } else {
        setRatingrowdata(jsonData);
        ////console.log(jsonData);
        //gridRef2.current.api.refreshCells();
      }
    };
  
    //Define Position Grid
  
const deletePosition = async (params) => {
      try {
        const token = localStorage.getItem("accessToken");
        const uid=parseInt(localStorage.getItem('userid'));
        const requestOptions = {
          method: "DELETE",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            userid: uid,
            teamid: team,
            positionid: params.value,
          }),
        };
  
        ////console.log(requestOptions.body)
        const response = await fetch(
          process.env.REACT_APP_RESTSERVER + "/deleteposition",
          requestOptions
        );
        //setNewteamadded([{time:Date()}]);
        setNewPositionadded([{ time: Date() }]);
      } catch (err) {
        console.error(err.message);
      }
    };
  
const getpositions = async () => {
      const token = localStorage.getItem("accessToken");
      const requestOptions = {
        headers: { "Content-Type": "application/json", Authorization: token },
      };
      const fetchPositions = async () => {
        const response = await fetch(
          process.env.REACT_APP_RESTSERVER + `/getpositions/${team}`,
          requestOptions
        );
        const jsonData = await response.json();
        if (jsonData === "Token Expired") {
          expireUser();
        } else {
          setPositionrowdata(jsonData);
          ////console.log(jsonData);
          //gridRef3.current.api.refreshCells();
        }
      };
      fetchPositions();
    };
  
const addNewPlayer = async (e) => {
      e.preventDefault();
      try {
        if (team === null) {
          alert("No Team Selected");
        } else {
          //const uid = parseInt(ider.id, 10);
          const uid=parseInt(localStorage.getItem('userid'));
      
          const token = localStorage.getItem("accessToken");
  
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", Authorization: token },
            body: JSON.stringify({
              userid: uid,
              firstname: "Default",
              freename: freename,
              teamid: team,
            }),
          };
          ////console.log(requestOptions.body)
          const response = await fetch(
            process.env.REACT_APP_RESTSERVER + "/createfreeplayer",
            requestOptions
          );
          const jsonData = await response.json();
          if (jsonData === "Token Expired") {
            expireUser();
          } else {
            setNewplayeradded(jsonData);
            ////console.log(newplayeradded[0]);
            //setFirstname("")
            //setLastname("")
            setFreename("");
          }
        }
      } catch (err) {
        console.error(err.message);
        alert('Crap');
      }
    };
  
const addNewPosition = async (e) => {
      e.preventDefault();
  
      //const uid = parseInt(ider.id, 10);
      const uid=parseInt(localStorage.getItem('userid'));
      const token = localStorage.getItem("accessToken");
  
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          userid: uid,
          teamid: team,
          positionname: positionname,
        }),
      };
      ////console.log(requestOptions.body)
  
      const response = await fetch(
        process.env.REACT_APP_RESTSERVER + "/createposition",
        requestOptions
      );
      const jsonData = await response.json();
  
      if (jsonData === "Token Expired") {
        expireUser();
      } else {
        setNewPositionadded([{ time: Date.now() }]);
        setPosition("");
      }
    };
  
const getResults = async()=>{
  
      const token = localStorage.getItem("accessToken");
      const requestOptions = {
        headers: { "Content-Type": "application/json", Authorization: token },
      };
  
      const responsestatus = await fetch(
        process.env.REACT_APP_RESTSERVER + `/ros2deep_getmodelstatus/${scenariofreeid}`,
        requestOptions
      );
      const jsonDatastatus = await responsestatus.json();
  try{
      if (jsonDatastatus[0].model_status === "feasible" && jsonDatastatus[0].model_totalvalue!==null) {
        //console.log('in result code');
        const response = await fetch(
          process.env.REACT_APP_RESTSERVER + `/ros2deep_getresults/${scenariofreeid}`,
          requestOptions
        );
        const jsonData = await response.json();
        setResultRowData(jsonData);
        // //console.log(jsonData);
        ////console.log("Results Returned");
        setPageCurrentLineup(1);
        gridRef5.current.api.paginationGoToFirstPage(); 
  
        const luc = jsonData[jsonData.length - 1];
        setLineupCount(luc.segment);
          ///populating power bar chart
         
          const chartresponse = await fetch(
            process.env.REACT_APP_RESTSERVER + `/ros_getpositionchart/${scenariofreeid}`,
            requestOptions
          );
          const chartjsonData = await chartresponse.json();
          
          try{
          setBarChartPower({
            labels:chartjsonData.map((data)=>data.positionname),
            datasets:[{
                label:"Strength By Position",
                data:chartjsonData.map((data)=>data.rating),
                  }]
          })
  
          
          }catch{
            setBarChartPower([])
          }
          const chartresponse3 = await fetch(
            process.env.REACT_APP_RESTSERVER + `/ros_getresultspowerchart/${scenariofreeid}`,
            requestOptions
          );
          const chartjsonData3 = await chartresponse3.json();
          
          try{
          setBarChartLineupPower({
            labels:chartjsonData3.map((data)=>data.segment),
            datasets:[{
                label:"Strength By Lineup",
                data:chartjsonData3.map((data)=>data.strength),
                  }]
          })
        }catch{setBarChartLineupPower([])}


  
  
          const chartresponse2 = await fetch(
            process.env.REACT_APP_RESTSERVER + `/fp_getptdistchart/${scenariofreeid}`,
            requestOptions
          );
          const chartjsonData2 = await chartresponse2.json();
          
          try{
          setBarChartPlayerdist({
            labels:chartjsonData2.map((data)=>data.freename),
            datasets:[{
                label:"Lineups Per Player",
                data:chartjsonData2.map((data)=>data.lineups),
                  }]
          })
  
        }catch{
          setBarChartPlayerdist([])
        }
    
  
  
  
      } else {
        setResultRowData([]);
       /*  setSolveMessage(
          '<span style="font-size:.87rem">The inputs prevent a feasible solution. \
            </br></br> <span style="text-decoration:underline;"> Some Things to Try:</span> \
            </br>Raise the Number of Lineups \
            </br> Raise/Lower Playing Time %</br>Set Max Value to 0 or Very High \
            </br></br>  View Tutorials Page For More</span> '
        ); */
      }
    } catch{}
  
  
  
    }
  
const solveModel = async (e) => {
      e.preventDefault();
      ReactGA.event({
        category: 'User',
        action: 'Solved Roster'
      });
  
      const getResults = async (jsn) => {
        const token = localStorage.getItem("accessToken");
        const requestOptions = {
          headers: { "Content-Type": "application/json", Authorization: token },
        };
  
        const responsestatus = await fetch(
          process.env.REACT_APP_RESTSERVER + `/ros2deep_getmodelstatus/${scenariofreeid}`,
          requestOptions
        );
        const jsonDatastatus = await responsestatus.json();
  
        if (jsonDatastatus[0].model_status === "feasible" && jsonDatastatus[0].model_totalvalue!==null) {
          //console.log('in result code');
          const response = await fetch(
            process.env.REACT_APP_RESTSERVER + `/ros2deep_getresults/${scenariofreeid}`,
            requestOptions
          );
          const jsonData = await response.json();
          setResultRowData(jsonData);
          ////console.log(jsonData);
          ////console.log("Results Returned");
          setMaxValue2deep(jsonData[0].maxvalue);
          setPageCurrentLineup(1);
          gridRef5.current.api.paginationGoToFirstPage(); 
  
          const luc = jsonData[jsonData.length - 1];
          setLineupCount(luc.segment);
            ///populating power bar chart
           
            const chartresponse = await fetch(
              process.env.REACT_APP_RESTSERVER + `/ros_getpositionchart/${scenariofreeid}`,
              requestOptions
            );
            const chartjsonData = await chartresponse.json();
            
            try{
            setBarChartPower({
              labels:chartjsonData.map((data)=>data.positionname),
              datasets:[{
                  label:"Strength By Position",
                  data:chartjsonData.map((data)=>data.rating),
                    }]
            })
          }catch{setBarChartPower([])}
  
          const chartresponse2 = await fetch(
            process.env.REACT_APP_RESTSERVER + `/fp_getptdistchart/${scenariofreeid}`,
            requestOptions
          );
          const chartjsonData2 = await chartresponse2.json();
          
          try{
          setBarChartPlayerdist({
            labels:chartjsonData2.map((data)=>data.freename),
            datasets:[{
                label:"Lineups Per Player",
                data:chartjsonData2.map((data)=>data.lineups),
                  }]
          })
  
        }catch{
          setBarChartPlayerdist([])
        }
      
        const chartresponse3 = await fetch(
          process.env.REACT_APP_RESTSERVER + `/ros_getresultspowerchart/${scenariofreeid}`,
          requestOptions
        );
        const chartjsonData3 = await chartresponse3.json();
        
        try{
        setBarChartLineupPower({
          labels:chartjsonData3.map((data)=>data.segment),
          datasets:[{
              label:"Strength By Lineup",
              data:chartjsonData3.map((data)=>data.strength),
                }]
        })
      }catch{setBarChartLineupPower([])}
  
  
        } else {
          setResultRowData([]);
          setSolveMessage(
            '<span style="font-size:.87rem">The inputs prevent a feasible solution. \
              </br></br> <span style="text-decoration:underline;"> Some Things to Try:</span> \
              </br>Raise the Number of Lineups \
              </br> Raise/Lower Playing Time %</br>Set Max Value to 0 or Very High \
              </br></br>  View Tutorials Page For More</span> '
          );
        }
      };
  
      const getSolution = async (jsn) => {
        const token = localStorage.getItem("accessToken");
        // //console.log('getting solution')
  
        const requestOptions = {
          headers: { "Content-Type": "application/json", Authorization: token },
        };

        if (fantasychecked === false){
        const response = await fetch(
          process.env.REACT_APP_RESTSERVER + `/ros2deep_SolveFree/${scenariofreeid}`,
          requestOptions
        );
        const jsonData = await response.json();
        getResults(jsonData);
        }

        if (fantasychecked === true){
          const response = await fetch(
            process.env.REACT_APP_RESTSERVER + `/rosfantasy_SolveFree/${scenariofreeid}`,
            requestOptions
          );
          const jsonData = await response.json();
          getResults(jsonData);
          }


        
        // //console.log(jsonData);
        // //console.log('solution returned');
       
      };
  
      ///Managing scenario inputs
      if (lineupmax > 20) {
        setLineupMax(20);
      }
      if (playpermin < 0) {
        setPlayPerMin(0);
      }
      if (playpermax > 100) {
        setPlayPerMax(100);
      }
      if (playpermin > playpermax) {
        setPlayPerMax(100);
      }
      if (playpermax < playpermin) {
        setPlayPerMin(0);
      }
      if (playercount > 100) {
        setPlayerCount(100);
      }
  


      const token = localStorage.getItem("accessToken");
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          scenarioid: scenariofreeid,
          ros_playercount: playercount,
          maxvalue: maxvalue
        }),
      };
  
      const response = await fetch(
        process.env.REACT_APP_RESTSERVER + "/ros2deep_updatefreescenario",
        requestOptions
      );
      const jsonData = await response.json();
      if (jsonData === "Token Expired") {
        expireUser();
      } else {
        ////console.log(jsonData);
        ////console.log('Scenario Set');
  
        getSolution(jsonData);
      }
    };
  
const updateRating = useCallback((event) => {
  var data = event.data;

  //const uid = parseInt(ider.id, 10);
  const uid=parseInt(localStorage.getItem('userid'));
  const token = localStorage.getItem("accessToken");

  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", Authorization: token },
    body: JSON.stringify({
      userid: uid,
      teamid: data.teamid,
      rating: event.newValue,
      teamplayerratingid: data.teamplayerratingid,
    }),
  };
  const updateratingcall = async () => {
    const response = await fetch(
      process.env.REACT_APP_RESTSERVER + "/updaterating",
      requestOptions
    );
    const jsonData = await response.json();

    const requestOptions2 = {
      headers: { "Content-Type": "application/json", Authorization: token },
    };
    
    
    const chartresponse = await fetch(
      process.env.REACT_APP_RESTSERVER + `/getresultsplayerchart/${data.teamid}`,
      requestOptions2
    );
    const chartjsonData = await chartresponse.json();
    
    
    setBarChartPlayer({
      labels:chartjsonData.map((data)=>data.freename),
      datasets:[{
          label:"Average Player Strength",
          data:chartjsonData.map((data)=>data.averagerating),
          backgroundColor:" #7FB069",
        
            }]
    })
    


  };

  updateratingcall();

  ////console.log('onCellValueChanged: ' + event.colDef.field + ' = ' + event.newValue + ' & ' +data.teamplayerratingid);
}, []);

const onBtNext = useCallback(() => {
  gridRef5.current.api.paginationGoToNextPage();
  setPageCurrentLineup(gridRef5.current.api.paginationGetCurrentPage() + 1); //+1 is because pages are zero based
}, []);

const onBtPrevious = useCallback(() => {
  gridRef5.current.api.paginationGoToPreviousPage();
  setPageCurrentLineup(gridRef5.current.api.paginationGetCurrentPage() + 1); //+1 is because pages are zero based
}, []);

const getPreselectedCriteria = async (objectiveid) => {
  const oid = parseInt(objectiveid);
  ////console.log(oid);
  const selectedItem = objectives.find((item) => item.objectiveid === oid);
  // //console.log(selectedItem.objectivename);

  setLineupMax(selectedItem.lineupcount);
  setPlayPerMin(selectedItem.minplayper * 100);
  setPlayPerMax(selectedItem.maxplayper * 100);
};
  
    return (
      <>
        {localStorage.getItem("userid") !== null ? <span></span> : <span></span>}
  
        <Container  fluid style={{ backgroundColor: "#f9f9f9" }}>
        
          <Row id="messagerow" style={{marginTop:"8px"}}>
                <Col>
            <Card style={{textAlign:"center",height:"40px",lineHeight:"40px"}}>
           
            <span style={{fontSize:".82rem"}}>

         
<PlayBtn style={{marginRight:"5px",marginBottom:"2px",marginTop:"3px",fontSize:"1.5rem",height:"35px",lineHeight:"35px"}}/>
 
 <Button onClick={handleModalShow} variant="primary" style={{height:"60%",width:"55px",marginTop:"3px",marginLeft:"5px",marginBottom:"3px",
 fontSize:".8rem",paddingLeft:"3px",paddingRight:"3px",paddingTop:"0",paddingBottom:"10px"}}>
  Tutorial</Button>
  <Button onClick={handleModalDemoShow} variant="primary" style={{height:"60%",width:"55px",marginTop:"3px",marginLeft:"5px",marginBottom:"3px",
 fontSize:".8rem",paddingLeft:"3px",paddingRight:"3px",paddingTop:"0",paddingBottom:"10px"}}>
  Demo</Button></span>
            </Card>
            </Col>
            <Col>
            <Card style={{textAlign:"center",height:"40px",lineHeight:"40px"}}>
              {istempuser ? 
              <Link to="/loginpg" style={{textDecoration:"none"}}><span style={{fontSize:".85rem",color:"darkred",fontWeight:"bold",textDecoration:"none"}}>
              <PersonFillAdd style={{marginRight:"5px",marginBottom:"5px",fontSize:"1.3rem",height:"35px",lineHeight:"35px"}}/>
              Create An Account Or Login To Save Your Team! </span> </Link>
              :
              <span style={{fontSize:".85rem"}}>
              <PersonCircle style={{color:"green",marginRight:"5px",marginBottom:"5px",fontSize:"1.3rem",height:"35px",lineHeight:"35px"}}/>
              Welcome: {localStorage.getItem('email')}
              </span> 
              }
            </Card>
            </Col>
            </Row>
        
          <Row id="setupRow" style={{ marginTop: "8px" }}>
            <Col>
              <Card style={{ backgroundColor: "#F4F4F4"}}>
                <Card.Header className="CardHeaderDarkBlue" >
                  <Button
                    id="ParamsDiv-header"
                    onClick={() => setOpenParams(!openparams)}
                    aria-controls="collapse-params"
                    aria-expanded={openparams}
                    className="CardHeaderButton"
                  >
                    <PersonCheck
                      style={{
                        marginRight: "4px",
                        marginTop: "0",
                        marginBottom: "0",
                        height: "100%",
                      }}
                    />
                    Roster Creation 
                  </Button>
                </Card.Header>
                <Collapse in={openparams}>
                  <div id="collapse-paramse">
                    <Row style={{ fontSize: ".8rem", padding: "8px" }}>
                      <Col 
                        xs={12}
                        lg={12}
                        xl={3}
  
                        style={fantasychecked ? {color:'grey',textAlign: "center",
                        padding: "0",
                        margin: "0",
                        marginBottom: "5px"
                      }:{color:'black',textAlign: "center",
                      padding: "0",
                      margin: "0"}
                          
                        }>
                             <div style={{height:"30px"}}>
                        Number of Players Needed:{" "}
                        <input
                         disabled = {(fantasychecked)? "disabled" : ""}
                          id="inputTeam"
                          type="number"
                          className="inputBox"
                          min="0"
                          max="50"
                          value={playercount}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10); // Parse input value to integer
                            if (!isNaN(value)) { // Check if the parsed value is a number
                              // Define your minimum and maximum values
                              const minValue = 0;
                              const maxValue = 100; // Example maximum value
                          
                              // Ensure the value is within the desired range
                              const newValue = Math.min(Math.max(value, minValue), maxValue);
                          
                              // Update the state with the validated value
                              setPlayerCount(newValue);
                            }
                          }}
                        />
                        </div>
                        </Col>
                        <Col 
                        xs={12}
                        lg={12}
                        xl={3}
  
                        style={{
                          textAlign: "center",
                          padding: "0",
                          margin: "0"
                        
                        }}>
                        <div style={{height:"30px"}}>
                        Max Value:{" "}
                        <input

                          id="inputTeam"
                          type="number"
                          className="inputBox"
                          min="0"
                          
                          value={maxvalue}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10); // Parse input value to integer
                            if (!isNaN(value)) { // Check if the parsed value is a number
                              // Define your minimum and maximum values
                              const minValue = 0;
                              const maxValue = 100000000; // Example maximum value
                          
                              // Ensure the value is within the desired range
                              const newValue = Math.min(Math.max(value, minValue), maxValue);
                          
                              // Update the state with the validated value
                              setMaxValue(newValue);
                            }
                          }}
                         
                        />
                        </div>
                    </Col>
                      <Col
                          lg={12}
                          xl={3}
                          className="d-flex justify-content-center align-items-center p-0 m-0">
                           <div  >
                           <Form.Check className="align-middle" style={{}}// prettier-ignore
                            checked={fantasychecked}
                            type="switch"
                            label="One Player Per Position (Fantasy Mode)"
                            id="disabled-custom-switch"
                            onChange={handleSwitchChange}
                              />
      
                           </div>
                      </Col>
  
                      <Col 
                           lg={12}
                           xl={3}
   
  
                        style={{
                          textAlign: "center",
                          padding: "0",
                          margin: "0",
                          marginBottom: "5px",
                        }}>
                      <div id="ExecuteButton">
                  <form
                      onSubmit={solveModel}
                      style={{margin:"0",padding:"0",fontSize:".9"
                    
                      }}
                    >
                      <button
                        className="executeModelButton"
  
                      >
                        <PlayFill
                          style={{
                            marginRight: "5px",
                            marginTop: "0px",
                            verticalAlign: "middle",
                            fontSize: ".9rem",
                          }}
                        />
                        Create Roster
                      </button>
                    </form>
                  </div>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </Card>
         
  
            </Col>
          </Row>
          <Row id="LogicRow" style={{marginTop:"8px"}}>
            <Col id="playercol" style={{minWidth:"220px"}} >
              <Card className="myCards">
                <Card.Header className="CardHeaderBlue" >
                  <Button
                    id="PlayersDiv-header2"
                    onClick={() => setOpenPlayers(!openplayers)}
                    aria-controls="collapse-players2"
                    aria-expanded={openplayers}
                    className="CardHeaderButton">
                    <BsFillPersonFill
                      style={{ marginRight: "4px", height: "100%" }}
                    />{" "}
                    Players
                  </Button>
                </Card.Header>
                <Collapse in={openplayers}>
                  <div id="collapse-players2" >
                    <div id="v2_playpos_inputs2" className="v2_playpos_inputs" >
                      <form onSubmit={addNewPlayer} >
                        <input
                          className="fsinput"
                          type="text"
                          value={freename}
                          placeholder="Add New Player"
                          onChange={(e) => setFreename(e.target.value)}
                        ></input>
                        <button className="fsbutton">
                          <BsFillPlusCircleFill />
                        </button>
                      </form>
                    </div>
                    <div className="v2_playpos_Grid">
                      <div className="ag-theme-alpine">
                        <AgGridReact
                          className="cusGrid"
                          ref={gridRef2}
                          columnDefs={columnDefsPlayers}
                          defaultColDef={defautColDefPlayers}
                          rowData={playerRowdata}
                          onGridReady={getplayers}
                          onSelectionChanged={onPlayerSelectionChanged}
                          rowSelection={"single"}
                          rowHeight={20}
                          onCellValueChanged={updatePlayer}
                          headerHeight={0}
                          overlayNoRowsTemplate={
                            "Add Players To Your Team Using Box Above"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Collapse>
              </Card>
            </Col>
            <Col id="positioncol" style={{minWidth:"220px"}}>
              <Card id="positioncard" className="myCards">
                <Card.Header className="CardHeaderBlue">
                    <Button
                    id="PositionsDiv-header"
                    onClick={() => setOpenPositions(!openpositions)}
                    aria-controls="collapse-positions2"
                    aria-expanded={openpositions}
                    className="CardHeaderButton">
                      <BsGridFill style={{ marginRight: "4px", height: "100%" }} />
                    Positions
                  </Button>
                </Card.Header>
                <Collapse in={openpositions}>
                <div id="collapse-positions2">
              
                  <div id="v2_playpos_inputs2" className="v2_playpos_inputs">
                   
                    <form onSubmit={addNewPosition} style={{ margin: "0",width:"100%" }} >
                      <input
                        className="fsinput"
                        type="text"
                        value={positionname}
                        placeholder="Add New Position"
                        onChange={(e) => setPosition(e.target.value)}
                      ></input>
                      <button className="fsbutton">
                        <BsFillPlusCircleFill />
                      </button>
                    </form>
                  </div>
                  <div className="v2_playpos_Grid">
                    <div className="ag-theme-alpine">
                      <AgGridReact
                        className="cusGrid"
                        ref={gridRef3}
                        columnDefs={columnDefsPositions}
                        defaultColDef={defautColDefPositions}
                        rowData={positionRowdata}
                        onGridReady={getpositions}
                        onSelectionChanged={onPositionSelectionChanged}
                        rowSelection={"single"}
                        rowHeight={20}
                        onCellValueChanged={updatePosition}
                        headerHeight={0}
                        overlayNoRowsTemplate={
                          "Add Positions To Your Team Using Box Above"
                        }
                      />
                    </div>
                  </div>
                </div>
              </Collapse>
  
              </Card>
            </Col>
            <Col id="ratingcol" style={{minWidth:"220px"}} >
              <Card id="ratingcard" className="myCards">
                <Card.Header id="ratingcardheader" className="CardHeaderBlue">
                <Button
                id="RatingsDiv-header"
                onClick={() => setOpenRatings(!openratings)}
                aria-controls="collapse-ratings2"
                aria-expanded={openratings}
                className="CardHeaderButton"
              >
                <BsPlusSlashMinus style={{ marginRight: "4px", height: "100%" }} />{" "}
                Ratings
              </Button>
                </Card.Header>
                <Collapse in={openratings}>
                <div id="collapse-ratings2">
                  <div className="v2_ratcategory">
                    <span style={{ fontWeight: "normal" }}>Editing: </span>
                    {ratingcategory}
                  </div>
                  <div className="ag-theme-alpine">
                    <div className="v2_playpos_Grid" style={{height:"290px"}}>
                      <AgGridReact
                        className="cusGrid"
                        ref={gridRef4}
                        columnDefs={columnDefsRatings}
                        defaultColDef={defautColDefRatings}
                        rowData={ratingRowdata}
                        //onGridReady={playerRowdata}
                        //onSelectionChanged={onPositionSelectionChanged}
                        rowSelection={"single"}
                        onCellValueChanged={updateRating}
                        rowHeight={20}
                        headerHeight={0}
                        singleClickEdit={true}
                        overlayNoRowsTemplate={
                          "Select A Player or Position To Update Ratings"
                        }
                      />
                    </div>
                  </div>
                </div>
              </Collapse>
              </Card>
            </Col>
            <Col md={4} id="lineupcol" style={{minWidth:"300px"}} >
              <Card id="lineupcard" className="myCards">
                <Card.Header id="lineupcardheader" className="CardHeaderGreen">
                <Button
                id="LineupsDiv-header"
                onClick={() => setOpenLineups(!openlineups)}
                aria-controls="collapse-lineups2"
                aria-expanded={openlineups}
                className="CardHeaderButton">
                <BsPersonLinesFill  style={{ marginRight: "4px", height: "100%" }} /> Roster
              </Button>
                </Card.Header>
                <Collapse in={openlineups}>
                <div id="collapse-lineups">
                  <div className="ag-theme-alpine">
                    <div className="v2_playpos_Grid" style={{height:"320px"}}>
                      <AgGridReact
                        className="cusGrid"
                        ref={gridRef5}
                        columnDefs={columnDefsResults}
                        defaultColDef={defautColDefResults}
                        rowData={resultrowdata}
                        //onGridReady={getTeams}
                        //onSelectionChanged={onSelectionChanged}
                        rowSelection={"single"}
                        rowHeight={20}
                        headerHeight={30}
                        //onCellValueChanged={updatePositionCount}
                        //accentedSort={true}
                        //pagination={true}
                        //suppressPaginationPanel={true}
                        //paginationPageSize={positionRowdata.length}
                        overlayNoRowsTemplate={solvemessage}
  
                        //overlayNoRowsTemplate= {"<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; \">This is a custom 'no rows' overlay</span>"}
                      />
                    </div>
                  </div>
                 {/*} <div id="pagination" className="paginationDiv">
                    <button
                      id="PreviousLineup"
                      className="pageButton"
                      onClick={onBtPrevious}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        border: "0px",
                        color: "darkgreen",
                        fontSize: ".9rem",
                        verticalAlign: "middle",
                        marginTop:"0px"
                      }}
                    >
                      <BsFillArrowLeftCircleFill />
                    </button>
                    Lineup: {pagecurrentlineup} of {lineupcount}
                    <button
                      id="NextLineup"
                      className="pageButton"
                      onClick={onBtNext}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        border: "0px",
                        color: "darkgreen",
                        fontSize: ".9rem",
                        verticalAlign: "middle",
                      }}
                    >
                      <BsFillArrowRightCircleFill />
                    </button>
                    <FiShare
                      style={{
                        fontSize: ".9rem",
                        marginLeft: "5px",
                        cursor: "pointer",
                        color: "darkgray",
                      }}
                    ></FiShare>
                    </div>*/}
  
                </div>
              </Collapse>
  
  
              </Card>
  
            </Col>
          </Row>
          <Row id="chartsRow" style={{marginTop:"8px"}}>
            <Col sm={12} md={6}>
                     
  
                   <Card style={{position:"relative",height:"auto"}}>
             <Bar data={barchartplayer} options={{ maintainAspectRatio: false }}>
             </Bar>
             </Card>
  
            
            </Col>
            <Col sm={12}md={6}>
            
            <Card style={{width:"100%",height:"100%"}}>
                      <Bar data={barchartpower} 
                      options={
                        { maintainAspectRatio: false ,
                          scales: {
                            y: {
                              min: "auto",
                              max: "auto",
                            }
                          }}
                        }>
  
                      </Bar>
  
            </Card>
           
            </Col>
            <Col sm={12}md={6} className="mt-2">
            
            <Card style={{width:"100%",height:"100%"}}>
                      <Bar data={barchartlineuppower} 
                      options={
                        { maintainAspectRatio: false ,
                          scales: {
                            y: {
                              min: "auto",
                              max: "auto",
                            }
                          }}
                        }>
  
                      </Bar>
  
            </Card>
           
            </Col>
           {/*} <Col sm={12}md={6} className="mt-2">
            
            <Card style={{width:"100%",height:"100%"}}>
                      <Bar data={barchartplayeridst} 
                      options={
                        { maintainAspectRatio: false ,
                          scales: {
                            y: {
                              min: "auto",
                              max: "auto",
                            }
                          }}
                        }>
  
                      </Bar>
  
            </Card>
           
                      </Col>*/}
  
  
          </Row>
          <Row id="RowFooter" style={{borderBottom:"20px"}}>
            <Col xs={12}
              style={{
                borderTop: "1px solid black",
                marginTop: "10px",
                textAlign: "center",
                fontSize: ".9rem",
                borderBottom:"20px"
              }}
            >
              <div>
              Copyright &copy; 2024 Niche-Analytics LLC - <Link to="/privacy" style={{textDecoration:"none",marginLeft:"5px"}}>Privacy Policy</Link> - 
              <Link to="/terms" style={{textDecoration:"none"}}> <span style={{marginLeft:"5px"}}>Terms of
              Service</span></Link>
              </div>
            </Col>
            <Col style={{height:"20px"}}>
            </Col>
          </Row>
  
  
          <Modal
          show={modalshow}
          onHide={handleModalClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-4490w"
          size="lg">
            <Modal.Header closeButton >
                Roster Selection Optimization Tutorial
            </Modal.Header>
            <Modal.Body className="px-0 py-0" >
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe width="799px" height="449px" src="https://www.youtube.com/embed/3QMb5x5UAE8?si=Y0Ry7VxI1i8f02nJ" title="TIP How To" allowFullScreen></iframe>
                </div>
            </Modal.Body>
          </Modal>
  
          <Modal
        show={modalDemoshow}
        onHide={handleModalDemoClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-4490w"
        size="lg">
          <Modal.Header closeButton >
             Build Roster Demo
          </Modal.Header>
          <Modal.Body className="px-0 py-0" >
              <div className="embed-responsive embed-responsive-16by9">
                <iframe width="799px" height="449px" src="https://www.youtube.com/embed/T15Tzo1LdIw?si=qfuK0qMrgKth7gTE" title="TIP How To" allowFullScreen></iframe>
              </div>
          </Modal.Body>
        </Modal>  
  
        </Container>
  
      </>
    );
  };
  
  export default RosterCreation;