import React,{useEffect, useState}  from "react";

import Signup from "../components/signup.component";





const Register = () => {
return(
    <div style={{}}>
   
   <Signup/>


  </div>
);


}

export default Register;