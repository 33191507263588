import React,{useEffect, useState}  from "react";




import { useParams,Redir, useNavigate } from "react-router-dom";

import '../App.css';
import '../components/LoginForm.css'
import '../components/SigninForm.css';


/////Added <base href="/" /> to index.html file to fix issues with router and parmams if stuff
///// starts blowing up then go remove and find another way


const ResetPassPage = (props) => {

    const [email, setEmail]=useState("");
    const [emailgood, setEmailGood]=useState(true);
    const [pwd,setPassword]=useState("");
    const [submitval, setSubmitVal]=useState("Submit");
    const [submitgood, setSubmitGood]=useState(true);

    //const [token, setToken]=useState("");
    const token=useParams();
    const navigate=useNavigate();

    
   


  
    //console.log(token.tkid);

const getEmail= async () => {
        //console.log('Here@');
    const requestOptions = {
        headers: { 'Content-Type': 'application/json' }
        }
       

    const tkn=token.tkid;
    const response=await  fetch(process.env.REACT_APP_RESTSERVER+`/ValidateReset/${tkn}`,requestOptions)
    const jsonData= await response.json();
   // console.log(jsonData);
    setEmail(jsonData);
   

    

    }

    const handleSubmit=async e =>{
        e.preventDefault();

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
               
                pwd:pwd,
                email:email
              })
            }
           
            const response=await fetch(process.env.REACT_APP_RESTSERVER+'/updatepassword',requestOptions);
            const jsonData= await response.json();

       // const response = await updatePassword({
          //  email,
         // pwd
       // });
       if (jsonData==='Password Updated'){
        setSubmitVal("Password Updated");
        setSubmitGood(true);
        window.location.href = "/loginpg";

        //Take to login page somehow
       }
       else
       {
        setSubmitVal("Password Not Updated");
        setSubmitGood(false);

        //Take to forgot password page somehow
       }
       //console.log(jsonData);
        //console.log('handler');
        
    }



///Send token to verification on server
///recieve back email address or expired
////if email present email (non-editable and password change box: on submit update password in DB and send them to login page)
////if expired send them to send email for forgot password page

//--/ValidateReset/:tkn
useEffect(()=>{


getEmail();

},[]);

useEffect(()=>{


   if (email==='Invalid Token'){
    
    setEmailGood(false);
    //console.log(emailgood);
   //console.log(email);

   }
   else
   {
    setEmailGood(true);
    //console.log(emailgood);
    //console.log(email);
   }
    
    },[email]);

useEffect(() =>{

//console.log(emailgood)

},[emailgood])
    return (
        <div>
       <div style={{marginTop:"150px"}} id='login-form'>

          <form>
        <h2 >Reset Password</h2>
        
            {emailgood ? 
                (
                 
                <div> 
                       {submitgood ? (<div>
                                        <h5>{email}</h5><label htmlFor="password">New Password:</label>
                                        <input type="password" value={pwd}  onChange={e => setPassword(e.target.value)} />
                                        <input type="submit" value={submitval} onClick={handleSubmit}/>
                                        </div>
                                        ):(
                                            <div>
                                            <label htmlFor="password">Password Reset Failed. Please Retry and Ensure Your Email Address Is Valid</label>
                                            <input type="submit" value='Return To Reset Password' onClick={() => navigate("/resetpassemailpage")}/>
                                            </div>
                                        )}


                </div>

               


                ) 
                : 
                (
                <div>
                <label htmlFor="password">Email Expired Or Invalid. Please return to reset password page.</label>
                <input type="submit" value='Return To Reset Password' onClick={() => navigate("/resetpassemailpage")}/>
                </div>
                )





            }
 <div id="fp">
        
             
              
              </div>
             
           
       
          
      </form>
      </div>
    
      </div>
       
    )

}

export default ResetPassPage;