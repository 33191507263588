import React, { useState,useEffect } from 'react';
import {BsClipboardData,BsShare,BsDiagram3,BsCardChecklist } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { IoRibbon } from "react-icons/io5";
import { VscAccount } from "react-icons/vsc";
import {Link} from 'react-router-dom';
import '../pages/pricing_table.css';
import '../App.css';
import './SigninForm.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ReactGA from 'react-ga4';

async function createUser(credentials) {
    return  fetch(process.env.REACT_APP_RESTSERVER+'/createuser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
    .then(data => data.json())
    .catch(error => console.warn(error));
      //.then(data => data.json())
   }
   
async function convertUser(credentials) {
    const token = localStorage.getItem("accessToken");
    return  fetch(process.env.REACT_APP_RESTSERVER+'/convertuser', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json', Authorization: token
      },
      body: JSON.stringify(credentials)
    })
    .then(data => data.json())
    .catch(error => console.warn(error));
      //.then(data => data.json())
   }
   

async function loginUser(credentials) {
    return fetch(process.env.REACT_APP_RESTSERVER+'/loginuser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }




export default function Signup() { 
    const [email,setEmail]=useState("");
    const [pwd,setPwd]=useState("");
    const [firstname,setFirstname]=useState("");
    const [lastname,setLastname]=useState("");
    const [phone,setPhone]=useState("");
    const [usernm,setUsernm]=useState("");
    const [usermessage, setUsermessage]=useState("");
    const navigate=useNavigate();
 

    

    const handleSubmit = async e => {
        e.preventDefault();
        setEmail(email.toLowerCase());
      //console.log(localStorage.getItem('istemp'));
      //console.log(localStorage.getItem('accessToken'));

        if (localStorage.getItem('istemp')==='yes' && (localStorage.getItem('accessToken'))!=null){
         
          const response = await convertUser({
            email,
            pwd,
            firstname,
            lastname, 
            phone, 
            usernm
          });
  
   
          

          if (response['added']==='N'){
              setUsermessage("The email " + response['email'] + " already exists please login or try another");

          }
              else {
                  //console.log(email);
               
                  const response2 = await loginUser({
                      email,
                      pwd
                    });

                    if ('accessToken' in response2) {
                      
                  

                        localStorage.setItem('accessToken', response2['accessToken']);
                        localStorage.setItem('refreshToken',response2['refreshToken']);
                        localStorage.setItem('user', response2['email']);
                        localStorage.setItem('userid',response2['userid']);
                        localStorage.setItem('email', response2['email']);


                        
                       ////console.log(localStorage.getItem('accessToken'));

                       //setUsermessage("Welcome "+ response['firstname'] + ". Please proceed to login.");
                      
                       navigate("/");
                        //window.location.href = "/";
                      //});
                      setEmail("");
                      setPwd("");
                      setFirstname("");
                      setLastname("");
                      setPhone("");
                      setUsernm("");
                    } else {
                      alert("Failed", response.message, "error");
                      setEmail("");
                      setPwd("");
                      setFirstname("");
                      setLastname("");
                      setPhone("");
                      setUsernm("");
                    }


                 
                       // window.location.href = "/loginpg";
              }
       
        }
        
        else
        
        {
          const response = await createUser({
            email,
            pwd,
            firstname,
            lastname, 
            phone, 
            usernm
          });

  
          

          if (response['added']==='N'){
              setUsermessage("The email " + response['email'] + " already exists please login or try another");

          }
              else {

                  const response2 = await loginUser({
                      email,
                      pwd
                    });

                    if ('accessToken' in response2) {
                      
                  

                        localStorage.setItem('accessToken', response2['accessToken']);
                        localStorage.setItem('refreshToken',response2['refreshToken']);
                        localStorage.setItem('user', response2['email']);
                        localStorage.setItem('userid',response2['userid']);
                        localStorage.setItem('email', response2['email']);


                        
                       ////console.log(localStorage.getItem('accessToken'));

                       //setUsermessage("Welcome "+ response['firstname'] + ". Please proceed to login.");
                      
                       navigate("/");
                        //window.location.href = "/";
                        setEmail("");
                        setPwd("");
                        setFirstname("");
                        setLastname("");
                        setPhone("");
                        setUsernm("");
                      //});
                    } else {
                      alert("Failed", response.message, "error");
                      setEmail("");
                      setPwd("");
                      setFirstname("");
                      setLastname("");
                      setPhone("");
                      setUsernm("");
                    }


                 
                       // window.location.href = "/loginpg";
              }



          
        }
   


  

            
           // //console.log(response);
          

           


           

      }

useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, []);
    

        return (
<>
<div style={{margin:"auto",paddingBottom:"30px",textAlign:"center",height:"80vh",marginTop:"50px"}}>

      <span style={{fontSize:"3rem",color:"#0567AD "}}><
         VscAccount style={{fontSize:"2.5rem",color:"#0567AD"}}/></span> 
         <h3 style={{fontSize:"1.7rem",fontWeight:"bold"}}>Register To Create Your Team</h3>
      {/*  <h6 className="subtitle font-weight-normal" style={{color:"gray",fontSize:"1.5rem"}}>Upgrade To  <a href="/pricing_table"> Premium </a> For More Options and More Advanced Anaytics</h6> */}
      
        <div id="login-form">
      
        <form>
          <div >
            
             <div>
            <label >Email</label>
            <input type="text" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>
            <div>
              <label >Password</label>
              <input type="password" value={pwd} onChange={e => setPwd(e.target.value)}/>
              </div>
              <div>
              <label >First Name</label>
              <input type="text" value={firstname} onChange={e => setFirstname(e.target.value)}/>
              </div>
              <div>
              <label >Last Name</label>
              <input type="text" value={lastname} onChange={e => setLastname(e.target.value)}/>
              </div>
              <div>
              <input type="submit" value="Submit" style={{marginBottom:"10px"}}
                onClick={handleSubmit}
                
              />
              <span > Already Have An Account? <Link to="/loginpg">Sign In</Link></span> 
            </div>
            <div style={{fontSize:".9rem",fontWeight:"bold",color:"darkred",marginTop:"5"}}>
            {usermessage}
            </div>
          </div>
         
        </form>
    
      </div>
      
      </div>
      <Container>
         <Row id="RowFooter" style={{borderBottom:"20px"}}>
          <Col xs={12}
            style={{
              borderTop: "1px solid black",
              marginTop: "10px",
              textAlign: "center",
              fontSize: ".9rem",
              borderBottom:"20px"
            }}
          >
            <div>
            Copyright &copy; 2024 Niche-Analytics LLC - <Link to="/privacy" style={{textDecoration:"none",marginLeft:"5px"}}>Privacy Policy</Link> - 
            <Link to="/terms" style={{textDecoration:"none"}}> <span style={{marginLeft:"5px"}}>Terms of
            Service</span></Link>
            </div>
          </Col>
          <Col style={{height:"20px"}}>
          </Col>
        </Row>
         </Container>
    

   
         
          
        
        
              </>
        );
    
}