
import React, { useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import '../App.css';
import './LoginForm.css'


import ReactGA from 'react-ga4';

async function loginUser(credentials) {
    return fetch(process.env.REACT_APP_RESTSERVER+'/loginuser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }

export default function Signin() {
    
    const [email, setUserName] = useState("");
    const [pwd ,setPassword] = useState("");
    const [email2,setEmail2]=useState("");
    const [aToken, setAToken]=useState("");
    const [rToken,setRToken]=useState("");
    const [fail,setFail]=useState("");
    const [userid, setUserid]=useState(null);
    const navigate=useNavigate();

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);


    const handleSubmit = async e => {
      e.preventDefault();
      
      setUserName(email.toLowerCase());

      const response = await loginUser({
        email,
        pwd
      });
      if ('accessToken' in response) {
        //alert("Success", response.message, "success", {
         // buttons: false,
          //timer: 2000,
        //})
        
        
        //.then((value) => {
          localStorage.setItem('accessToken', response['accessToken']);
          localStorage.setItem('refreshToken',response['refreshToken']);
          localStorage.setItem('email', response['email']);
          localStorage.setItem('userid',response['userid']);
          
          setEmail2(email.toLowerCase());

          setAToken(localStorage.getItem('accessToken'));
          setRToken(localStorage.getItem('refreshToken'));

          navigate("/");
          //window.location.href = "/";
        //});
      } else {

        //alert("Failed", response.message, "error");
        setFail('User Login failed check email and password');
      }
    }
  
    return (
    <>
      
    <div style={{margin:"auto",paddingBottom:"30px",textAlign:"center"}}>


     <div id="login-form">
      
   
     <form>
        <h6 style={{fontSize:"1.3rem",color:"darkgray"}}>Login To Your Account</h6>
            <label htmlFor="username">Email:</label>

            <input type="text" id="username" value={email} 
            onChange={e => setUserName(e.target.value)}/>

             <label htmlFor="password">Password:</label>

              <input type="password" value={pwd} 
                onChange={e => setPassword(e.target.value)} />

              <input type="submit" value="Submit" onClick={handleSubmit}/>
              <div id="fp">
              <Link  to="/resetpassemailpage"> Forgot Password?</Link>
             
           
              </div>
              <p> <span style={{marginTop:'10px'}}>New Here? <Link to="/register">Register </Link> To Get Started</span></p>
           
       
          
      </form>
          
      <div style={{color:"Red",fontWeight:"bold"}}>{fail}</div>
      
      {/*
          <div>Welcome: {email2} </div>
          <div>Token: {aToken}</div>
            <div>RToken: {rToken}</div>
          <div>{fail}</div>
            */}
         </div>
         </div>
         </>
    
    );
  }