import  React,{useState}  from "react";
import {BsClipboardData,BsShare,BsDiagram3,BsCardChecklist } from "react-icons/bs";
import { IoRibbon } from "react-icons/io5";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom';

import './pricing_table.css';



const Pricing = () => {
    const [notifyemail, setNotifyEmail]=useState("");
    const [buttontext, setButtonText]=useState("Notify Me");

    const updateNotify=async e =>{
      e.preventDefault();
       //alert(notifyemail);
      

       const requestOptions = { method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ 
          emailnotify:notifyemail

         })
       }
        const response=await  fetch(process.env.REACT_APP_RESTSERVER+'/notifyemail',requestOptions)
        const jsonData= await response.json();
        //console.log(jsonData);
        

        setButtonText("You Will Be Notified!")
        setNotifyEmail(" ");

    }
  
    return (
<div style={{height:"100%",backgroundImage:"linear-gradient(white, #F7f7f9"}}>
<div className="pricing7 py-5" >
  <div className="container" >
    <div className="row justify-content-center" style={{marginTop:"0px",marginBottom:"30px"}}>
      <div className="col-md-8 text-center">
      <span style={{fontSize:"5rem",color:"#0567AD"}}>< IoRibbon style={{fontSize:"2.5rem"}}className="icon-emotsmile display-6 text-info"></IoRibbon></span> <h3 style={{fontSize:"1.5rem"}}>Go Premium For More Features And Deeper Analytics</h3>
        <h6 className="subtitle font-weight-normal" style={{color:"gray"}}>We offer 100% satisafaction and Money back Guarantee</h6>
      </div>
    </div>
    

    <div className="row mt-4">
     
      <div className="col-md-7">
        <div className="row">
         
          <div className="col-lg-6">
            <div className="card border-0 mb-4" style={{backgroundColor:"transparent"}}>
              <i className="icon-emotsmile display-6 text-info"><BsDiagram3 /></i>
              <h5 className="font-weight-medium mt-3">Unlimited Teams/Lineups</h5>
              <p className="mt-3" style={{color:"#313A3A"}}>Create multiple teams and share players across your teams.</p>
            </div>
          </div>
        
          <div className="col-lg-6">
            <div className="card border-0 mb-4" style={{backgroundColor:"transparent"}}>
              <i className="icon-mouse display-6 text-info"><BsCardChecklist /></i>
              <h5 className="font-weight-medium mt-3">Multiple Objectives</h5>
              <p className="mt-3" style={{color:"#313A3A"}}>Create optimal lineups focused on even playing time, team strength or other goals.</p>
            </div>
          </div>
        
          <div className="col-lg-6">
            <div className="card border-0 mb-4" style={{backgroundColor:"transparent"}}>
              <i className="icon-support display-6 text-info"><BsShare /></i>
              <h5 className="font-weight-medium mt-3">Collaborate</h5>
              <p className="mt-3" style={{color:"#313A3A"}}>Share lineups, allow others to contribute to lineup optimization, import CSV and more.</p>
            </div>
          </div>
         
          <div className="col-lg-6">
            <div className="card border-0 mb-4" style={{backgroundColor:"transparent"}}>
              <i className="icon-hourglass display-6 text-info"><BsClipboardData /></i>
              <h5 className="font-weight-medium mt-3">Additional Analytics</h5>
              <p className="mt-3" style={{color:"#313A3A"}}>Additional analytics such as game scenario insights, predictive AI and more.</p>
            </div>
          </div>
          
        </div>
      </div>
     
      <div className="col-md-4 ml-auto pricing-box align-self-center" >
        <div className="card border" style={{backgroundColor:"white",borderWidth:".5px",borderStyle:"solid",borderRadius:"5px",marginTop:"10px",boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"}}>
          <div className="card-body p-4 text-center">
            <h5>Premium</h5>
            <sup style={{color:"black",fontSize:"18px"}} >$</sup><span className="text-dark display-5" 
                            style={{color:"black",fontSize:"34px"}}>5.99</span>
            <h6 className="font-weight-light font-14" style={{fontSize:"16px"}}>Monthly</h6>
            <p className="mt-4" style={{color:"#313A3A"}}>The Premium subscription allows you unrestrcited access to the Team Insights Pro platform.</p>
            <p className="mt-4" style={{color:"#313A3A",padding:"5px"}}>While currently still in development, this service is <span style={{fontWeight:"bold",fontSize:"12px"}}>Coming Soon!</span></p>

          </div>
          <div >
          <form style={{margin:"0px"}} onSubmit={updateNotify}>
       
            <input id="inputNotify" className="inputBox" style={{width:"90%",marginLeft:"15px",textAlign:"left"}}
                    value={notifyemail}  onChange = {e => setNotifyEmail(e.target.value)} placeholder="Enter Your Email To Be Notified">

            </input>
            <button className="btn btn-info-gradiant p-3 btn-block text-white border-0" style={{width:"100%",padding:"5px",color:"white",marginTop:"10px"}}>{buttontext}</button>
          </form>
          </div>
        </div>
     
      </div>
    
    </div>
  </div>
</div>
<div style={{marginTop:"10px"}}>

</div>

<Container>
 <Row id="RowFooter" style={{borderBottom:"20px"}}>
  <Col xs={12}
    style={{
      borderTop: "1px solid black",
      marginTop: "10px",
      textAlign: "center",
      fontSize: ".9rem",
      borderBottom:"20px"
    }}
  >
    <div>
    Copyright &copy; 2024 Niche-Analytics LLC - <Link to="/privacy" style={{textDecoration:"none",marginLeft:"5px"}}>Privacy Policy</Link> - 
    <Link to="/terms" style={{textDecoration:"none"}}> <span style={{marginLeft:"5px"}}>Terms of
    Service</span></Link>
    </div>
  </Col>
  <Col style={{height:"20px"}}>
  </Col>
</Row>
 </Container>
 <h1 style={{color:"white",fontSize:".1rem"}}>TeamInsightsPro.com Optimize Rosters and Lineups Free</h1>  
</div>
 
    


    
)};

export default Pricing;