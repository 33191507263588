import React, { useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {Link} from 'react-router-dom';
import '../App.css';
import './LoginForm.css'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';





export default function Logout(props) {


    const [email, setEmail] = useState("");
   
    const navigate=useNavigate();

    const logoutuser = async e=>{
        e.preventDefault();
        localStorage.removeItem('email');
        localStorage.removeItem('userid');
        localStorage.removeItem('accessToken');
        localStorage.setItem('istemp','yes');
        localStorage.setItem('key', 'value')
    
        //console.log('loggedOut');
       
        window.location.reload();
        //navigate('/loginpg');  
        
    };

    useEffect ( () =>  {
       
  setEmail(localStorage.getItem('email'));
     
        
    },[]);




    return (
        <>
    <div style={{margin:"auto",paddingBottom:"30px",textAlign:"center",height:"80vh"}}>


     <div id="login-form">

     <form>
        <h2 style={{fontSize:'16px',fontWeight:"normal"}}>Currently Logged In As:<br/><br/>{email}</h2>
          

              <input type="submit" value="Logout" onClick={logoutuser}/>
              
           
       
          
      </form>
            
     
      {/*
          <div>Welcome: {email2} </div>
          <div>Token: {aToken}</div>
            <div>RToken: {rToken}</div>
          <div>{fail}</div>
            */}
         </div>

         </div>
         <Container>
         <Row id="RowFooter" style={{borderBottom:"20px"}}>
          <Col xs={12}
            style={{
              borderTop: "1px solid black",
              marginTop: "10px",
              textAlign: "center",
              fontSize: ".9rem",
              borderBottom:"20px"
            }}
          >
            <div>
            Copyright &copy; 2024 Niche-Analytics LLC - <Link to="/privacy" style={{textDecoration:"none",marginLeft:"5px"}}>Privacy Policy</Link> - 
            <Link to="/terms" style={{textDecoration:"none"}}> <span style={{marginLeft:"5px"}}>Terms of
            Service</span></Link>
            </div>
          </Col>
          <Col style={{height:"20px"}}>
          </Col>
        </Row>
         </Container>
         </>
    );
}