import React,{useEffect, useState}  from "react";
import LoginForm from "../components/login.component";
import Signup from "../components/signup.component";
import Logout from "../components/logout.component";
import ResetPassSendEmail from "../components/resetpasssendemail.component";



const ResetPassEmailPage = () => {
    return(
<div style={{marginTop:'120px'}}>
   
   <ResetPassSendEmail></ResetPassSendEmail>


  </div>
  

    );
}
export default ResetPassEmailPage;